<div>
  <div class="mb-6 flex flex-wrap items-center justify-center gap-4 lg:justify-end print:hidden">
    <button type="button" class="btn btn-info gap-2" (click)="sendVoucher()">
      <icon-send />
      {{ "vaucher.send" | translate }}
    </button>

    <button type="button" class="btn btn-primary gap-2" (click)="printVoucher()">
      <icon-printer />
      {{ "vaucher.print" | translate }}
    </button>

    <button type="button" class="btn btn-warning gap-2" (click)="navigateToEditVoucher()">
      <icon-edit />
      {{ "vaucher.edit" | translate }}
    </button>

    <app-file-history [isShowingSidebar]="isShowingSidebar" [entityname]="entityname" [id]="id" (toggleSidebar)="isShowingSidebar = !isShowingSidebar"></app-file-history>
  </div>
  <app-voucher-template [voucher]="voucher"></app-voucher-template>
  <modal #modal class="modal-top custom-modal">
    <ng-template #modalBody>
      <app-file-send
        [title]="'voucher_sending_form_title' | translate"
        fileType="voucher"
        [passangers]="voucher.passengers"
        [sendDocumentFn]="voucherService.sendVoucher(this.voucher.id)"
        [messageRs]="'sending_voucher_RS_message' | translate"
        [messageEn]="'sending_voucher_EN_message' | translate"
        (notifyWhenSend)="finishSending()"
      >
        <table class="mb-4 w-full table-auto">
          <thead>
            <tr>
              <th class="px-4 py-2">{{ "contractsendemail.name" | translate }}</th>
              <th class="px-4 py-2">{{ "contractsendemail.surname" | translate }}</th>
              <th class="px-4 py-2">{{ "contractsendemail.email" | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let passenger of voucher.passengers">
              <td class="border px-4 py-2">{{ passenger.name }}</td>
              <td class="border px-4 py-2">{{ passenger.surname }}</td>
              <td class="border px-4 py-2">{{ passenger.email }}</td>
            </tr>
          </tbody>
        </table>
        <table class="table-responsive mb-3 w-full border-gray-400">
          <thead class="border-top: 2px">
            <tr class="ng-star-inserted col-span-1 border border-gray-300 bg-gray-100 sm:col-span-3">
              <th class="border border-gray-300 sm:col-span-1">{{ "vouchersendemail.hotel" | translate }}</th>
              <th class="border border-gray-300 sm:col-span-1">{{ "vouchersendemail.agent" | translate }}</th>
              <th class="border border-gray-300 sm:col-span-1">{{ "vouchersendemail.checkin" | translate }}</th>
              <th class="border border-gray-300 sm:col-span-1">{{ "vouchersendemail.checkout" | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr class="ng-star-inserted col-span-1 border border-gray-300 bg-gray-50 sm:col-span-3">
              <td class="border border-gray-300 sm:col-span-1">{{ this.voucher.hotelLabel }}</td>
              <td class="border border-gray-300 sm:col-span-1">{{ this.voucher.agent }}</td>
              <td class="border border-gray-300 sm:col-span-1">{{ this.voucher.checkin | date : "dd.MM.yyyy" }}</td>
              <td class="border border-gray-300 sm:col-span-1">{{ this.voucher.checkout | date : "dd.MM.yyyy" }}</td>
            </tr>
          </tbody>
          <thead class="border-top: 2px">
            <tr class="ng-star-inserted border border-gray-300 bg-gray-100">
              <th class="border border-gray-300">{{ "vouchersendemail.createdate" | translate }}</th>
              <th class="border border-gray-300">{{ "vouchersendemail.rooms" | translate }}</th>
              <th class="border border-gray-300">{{ "vouchersendemail.createbyfullname" | translate }}</th>
              <th class="border border-gray-300">{{ "vouchersendemail.transport" | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr class="ng-star-inserted border border-gray-300 bg-gray-50">
              <td class="border border-gray-300">{{ this.voucher.createDate | date : "dd.MM.yyyy" }}</td>
              <td class="border border-gray-300">{{ this.voucher.room }}</td>
              <td class="border border-gray-300">{{ this.voucher.createdByFullName }}</td>
              <td class="border border-gray-300">{{ this.voucher.transport }}</td>
            </tr>
          </tbody>
        </table>
      </app-file-send>
    </ng-template>
  </modal>
</div>
