import { catchError, Observable, of } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { NgSelectComponent } from "@ng-select/ng-select";
import { PopupType } from "src/app/shared/enums/popup-types";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TravelsService } from "../../travels/services/travels.service";
import { ETouristService } from "src/app/shared/services/etourist.service";
import { ExchangeRateService } from "src/app/shared/services/exchangeRates.service";
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { ETouristCodebookEntry, ETouristPlanResponseToReturnDto, ETuristSaleRecord } from "src/app/shared/models/etourist";
import { GroupTravelsService } from "../../group-travels/services/group-travels.service";

@Component({
  selector: "app-travel-e-tourist-form",
  templateUrl: "./travel-e-turist-form.component.html",
})
export class TravelETuristFormComponent implements OnInit, AfterViewInit {
  loading = false;
  symbol?: string;
  form!: FormGroup;
  currency?: string;
  @Input() id!: number;
  @Input() bruto!: number;
  amount: number = this.bruto;
  @Input() nbsExchangeRate!: number;
  @Input() eTuristTransportCode!: string;
  @Input() eTuristAccommodationCode!: string;
  @Output() formSubmit = new EventEmitter<null>();
  exchangeRate: number = this.nbsExchangeRate;
  selectedTransport!: ETouristCodebookEntry | null;
  selectedService!: ETouristCodebookEntry | null;
  protected currencySubscriber$!: Observable<ETouristCodebookEntry[]>;
  protected transportSubscriber$!: Observable<ETouristCodebookEntry[]>;
  protected accomodationSubscriber$!: Observable<ETouristCodebookEntry[]>;
  protected travelPlansSubscriber$!: Observable<ETouristPlanResponseToReturnDto[]>;
  @ViewChild("confirmationNumberSelect") confirmationNumberSelect!: NgSelectComponent;
  @Input() uniqueIdentifier!: number | undefined;
  @Input() IsGroupETourist!: boolean;
  @Input() organizerConfirmationNumber!: string | null;

  constructor(
    private travelService: TravelsService,
    private eTuristService: ETouristService,
    public translate: TranslateService,
    public fb: FormBuilder,
    public exchangeService: ExchangeRateService,
    public groupTravelService: GroupTravelsService
  ) {}

  ngOnInit() {
    this.currencySubscriber$ = this.eTuristService.getCurrecy().pipe(
      catchError((error) => {
        showMessage(PopupType.Danger, error.error);
        return of([]);
      })
    );
    this.transportSubscriber$ = this.eTuristService.getTransport().pipe(
      catchError((error) => {
        showMessage(PopupType.Danger, error.error);
        return of([]);
      })
    );
    this.travelPlansSubscriber$ = this.eTuristService.getTravelPlans().pipe(
      catchError((error) => {
        showMessage(PopupType.Danger, error.error);
        return of([]);
      })
    );
    this.accomodationSubscriber$ = this.eTuristService.getAccomodation().pipe(
      catchError((error) => {
        showMessage(PopupType.Danger, error.error);
        return of([]);
      })
    );

    this.initializeForm();

    this.currencySubscriber$.subscribe((currencies: any[]) => {
      const defaultCurrency = currencies.find((currency) => currency.code === "251");
      if (defaultCurrency) {
        this.currency = defaultCurrency.name;
        this.symbol = "€";
        this.setAmount(defaultCurrency.name);
        this.setExchangeRate(defaultCurrency.name);
        this.form.get("currencyTypeId")?.setValue(defaultCurrency);
      }
    });
    this.initializeTravelData();
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.confirmationNumberSelect.focus();
    }, 0);
  }

  initializeTravelData() {
    this.transportSubscriber$.pipe(catchError(() => of([]))).subscribe((transportList) => {
      this.selectedTransport = transportList.find((t) => t.code == this.eTuristTransportCode) || null;
      if (this.selectedTransport && this.selectedService) {
        this.updateForm();
      }
    });
    this.accomodationSubscriber$.pipe(catchError(() => of([]))).subscribe((accomodationList) => {
      this.selectedService = accomodationList.find((t) => t.code === this.eTuristAccommodationCode) || null;
      if (this.selectedTransport && this.selectedService) {
        this.updateForm();
      }
    });
    if (this.uniqueIdentifier) {
      this.travelPlansSubscriber$.pipe(catchError(() => of([]))).subscribe((plans) => {
        const selectedPlan = plans.find((t) => t.uniqueIdentifier === this.uniqueIdentifier) || null;
        if (selectedPlan) {
          this.form.get("jIDNumber")?.setValue(selectedPlan);
        }
      });
    }
  }
  updateForm() {
    this.form.patchValue({
      transportTypeId: this.selectedTransport,
      accomodationTypeId: this.selectedService,
    });
  }
  currencySelect(event: any) {
    this.currency = event.name;
    this.symbol = this.getSymbol(event.name);
    this.setExchangeRate(event.name);
  }
  initializeForm() {
    this.form = this.fb.group({
      jIDNumber: [null, Validators.required],
      amount: [this.bruto * this.nbsExchangeRate, Validators.required],
      currencyTypeId: [null, Validators.required],
      transportTypeId: [this.selectedTransport, Validators.required],
      accomodationTypeId: [this.selectedService, Validators.required],
      organizerConfirmationNumber: [this.organizerConfirmationNumber],
    });
  }

  getAccountData() {
    const { jIDNumber, amount, currencyTypeId, transportTypeId, accomodationTypeId, organizerConfirmationNumber } = this.form.value as ETuristSaleRecord;
    return {
      jIDNumber,
      amount,
      currencyTypeId,
      transportTypeId,
      accomodationTypeId,
      organizerConfirmationNumber,
    };
  }
  customSearch(term: string, item: { travelAgencyName: string; travelAgencyIdentificationNumber: string; uniqueIdentifier: number; name: string }): boolean {
    if (!term) return true;

    const terms = term.toLowerCase().replace(/\s+/g, " ").trim().split(" ");

    const searchableString = [item.travelAgencyName, item.travelAgencyIdentificationNumber, item.uniqueIdentifier.toString(), item.name].join(" ").toLowerCase().replace(/\s+/g, "");

    return terms.every((word) => searchableString.includes(word));
  }

  createAccountData() {
    this.loading = true;
    const accountData = this.getAccountData();
    const subscription = this.travelService.addEvidentionToETurist(accountData.jIDNumber.uniqueIdentifier, this.id, this.exchangeRate, accountData).subscribe({
      next: (res) => {
        this.loading = false;
        subscription.unsubscribe();
        if (res.item2) {
          showMessage(PopupType.Danger, res.item2);
        } else {
          showMessage(PopupType.Success, this.translate.instant("popup.accounting_data_created_successfully"));
          this.formSubmit.emit();
        }
      },
      error: () => {
        this.loading = false;
        subscription.unsubscribe();
        showMessage(PopupType.Danger, this.translate.instant("popup.error_while_creating_accounting_data"));
      },
    });
  }

  createGroupTravelAccountData() {
    this.loading = true;
    const accountData = this.getAccountData();
    const subscription = this.groupTravelService.addEvidentionToETurist(accountData.jIDNumber.uniqueIdentifier, this.id, this.exchangeRate, accountData).subscribe({
      next: (res) => {
        this.loading = false;
        subscription.unsubscribe();
        if (res.item2) {
          showMessage(PopupType.Danger, res.item2);
        } else {
          showMessage(PopupType.Success, this.translate.instant("popup.accounting_data_created_successfully"));
          this.formSubmit.emit();
        }
      },
      error: () => {
        this.loading = false;
        subscription.unsubscribe();
        showMessage(PopupType.Danger, this.translate.instant("popup.error_while_creating_accounting_data"));
      },
    });
  }

  save() {
    if (this.form.invalid) {
      showMessage(PopupType.Warning, this.translate.instant("popup.please_fill_all_required_fields"));
      this.form.markAllAsTouched();
      return;
    }
    if (this.IsGroupETourist) {
      this.createGroupTravelAccountData();
    } else {
      this.createAccountData();
    }
  }

  setExchangeRate(currency: string) {
    if (currency === "RSD" || currency === "EUR") {
      const exchangeSubscriber = this.exchangeService.getExchangeRate(currency === "RSD" ? "eur" : currency.toLowerCase()).subscribe({
        next: (res) => {
          this.exchangeRate = res;
          this.nbsExchangeRate = res;
          this.setAmount(currency);
          exchangeSubscriber.unsubscribe();
        },
      });
    } else {
      const exchangeSubscriber = this.exchangeService.getExchangeRate("eur").subscribe({
        next: (res) => {
          this.amount = this.bruto * res;
          exchangeSubscriber.unsubscribe();

          const secondSubscriber = this.exchangeService.getExchangeRate(currency.toLowerCase()).subscribe({
            next: (finalRes) => {
              this.exchangeRate = finalRes;
              this.nbsExchangeRate = res / finalRes;
              this.setAmount(currency);
              secondSubscriber.unsubscribe();
            },
          });
        },
      });
    }
  }

  setAmount(currency: string) {
    if (currency === "EUR") this.amount = this.bruto;
    else {
      this.amount = currency === "RSD" ? this.bruto * this.exchangeRate : this.amount / this.exchangeRate;
    }
    this.form.get("amount")?.setValue(this.amount);
  }

  getSymbol(currency: string) {
    switch (currency) {
      case "RSD":
        return "RSD";
      case "EUR":
        return "€";
      case "USD":
        return "$";
      case "CHF":
        return "CHF";
      default:
        return "€";
    }
  }

  public toFixed = (value: string | number | undefined | null): string => {
    if (value === null || value === undefined || value === "") {
      return "";
    }

    const formattedValue = String(value).split(" ").join("");

    if (formattedValue.includes(".") && formattedValue.split(".").length === 2) {
      const decimalLength = formattedValue.split(".")[1]?.length;
      if (decimalLength && decimalLength > 2) {
        return parseFloat(formattedValue).toFixed(2);
      }
    }
    return formattedValue;
  };
}
