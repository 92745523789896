<div class="pt-5">
  <div class="mb-5 flex overflow-y-auto whitespace-nowrap border-b border-[#ebedf2] font-semibold dark:border-[#191e3a]">
    <a
      href="javascript:;"
      class="flex gap-2 border-b border-transparent p-4 !outline-none hover:border-primary hover:text-primary"
      [ngClass]="{ '!border-primary text-primary': activeTab.toLowerCase() === 'profile' }"
      (click)="activeTab = 'profile'"
    >
      <icon-user />
      Profile 
<!-- //TODO:: translate -->
    </a>
    <a
      href="javascript:;"
      class="flex gap-2 border-b border-transparent p-4 !outline-none hover:border-primary hover:text-primary"
      [ngClass]="{ '!border-primary text-primary': activeTab.toLowerCase() === 'picklists' }"
      (click)="activeTab = 'picklists'"
    >
      <icon-dollar-sign-circle />
      {{ "picklists" | translate }}
    </a>
    <a
      href="javascript:;"
      class="flex gap-2 border-b border-transparent p-4 !outline-none hover:border-primary hover:text-primary"
      [ngClass]="{ '!border-primary text-primary': activeTab.toLowerCase() === 'office-branches' }"
      (click)="activeTab = 'office-branches'"
    >
      <icon-company class="h-5 w-5" />
      {{ "offices" | translate }}
    </a>
    <a
      href="javascript:;"
      class="flex gap-2 border-b border-transparent p-4 !outline-none hover:border-primary hover:text-primary"
      [ngClass]="{ '!border-primary text-primary': activeTab.toLowerCase() === 'danger-zone' }"
      (click)="activeTab = 'danger-zone'"
    >
      <icon-phone />
      Danger Zone
<!-- //TODO:: translate -->
    </a>
  </div>
  <div>
    <div *ngIf="activeTab.toLowerCase() === 'profile'">
      <app-user-profile />
    </div>
    <div *ngIf="activeTab.toLowerCase() === 'picklists'">
      <div class="panel space-y-8">
        <div class="flex flex-col">
          <ng-container *ngFor="let type of typeList; index as i">
            <app-settings-create [type]="type" [title]="titleList[i]" [accordion]="accordion" [orderNumber]="i" (updateActiveAccordion)="updateAccordion($event)"></app-settings-create>
          </ng-container>
        </div>
      </div>
    </div>
    <div *ngIf="activeTab.toLowerCase() === 'office-branches'" class="switch">
      <div class="panel">
        <app-office-branch-list *appHasRole="['Admin']"></app-office-branch-list>
      </div>
    </div>
<!-- //TODO:: translate -->
    <div *ngIf="activeTab.toLowerCase() === 'danger-zone'" class="switch">
      <div class="grid grid-cols-1 gap-5 md:grid-cols-3">
        <div class="panel space-y-5">
          <h5 class="mb-4 text-lg font-semibold">Purge Cache</h5>
          <p>Remove the active resource from the cache without waiting for the predetermined cache expiry time.</p>
          <button class="btn btn-secondary">Clear</button>
        </div>
        <div class="panel space-y-5">
          <h5 class="mb-4 text-lg font-semibold">Deactivate Account</h5>
          <p>You will not be able to receive messages, notifications for up to 24 hours.</p>
          <label class="relative h-6 w-12">
            <input type="checkbox" class="custom_switch peer absolute z-10 h-full w-full cursor-pointer opacity-0" id="custom_switch_checkbox7" />
            <span
              for="custom_switch_checkbox7"
              class="block h-full rounded-full bg-[#ebedf2] before:absolute before:bottom-1 before:left-1 before:h-4 before:w-4 before:rounded-full before:bg-white before:transition-all before:duration-300 peer-checked:bg-primary peer-checked:before:left-7 dark:bg-dark dark:before:bg-white-dark dark:peer-checked:before:bg-white"
            ></span>
          </label>
        </div>
        <div class="panel space-y-5">
          <h5 class="mb-4 text-lg font-semibold">Delete Account</h5>
          <p>Once you delete the account, there is no going back. Please be certain.</p>
          <button class="btn btn-danger btn-delete-account">Delete my account</button>
        </div>
      </div>
    </div>
  </div>
</div>
