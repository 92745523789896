import { Router } from "@angular/router";
import { ModalComponent } from "angular-custom-modal";
import { SaleBase } from "src/app/domain/sales/models/sale";
import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { PaymentService } from "./../../../domain/sales/services/payment.service";

@Component({
  selector: "app-invoice",
  templateUrl: "./invoice.component.html",
  styleUrls: ["./invoice.component.scss"],
})
export class InvoiceComponent {
  @Input() title = "Confirmation";
  @Input() message = "Are you sure you want to proceed?";
  @Input() confirmButtonText = "Confirm";
  @Input() cancelButtonText = "Cancel";
  @Input() plan!: SaleBase;
  @Input() isOnlyAvans = false;
  @Input() navigateToRoute!: string;
  @ViewChild("modal") modal!: ModalComponent;
  @Output() confirmed = new EventEmitter<boolean>();
  isLoading!: boolean;

  constructor(public paymentService: PaymentService, private router: Router) {}

  sendInvoice() {
    this.modal.open();
  }
  printInvoice() {
    window.print();
  }
  getCurrentDate(): Date {
    return new Date();
  }

  dismiss() :void{
    this.confirmed.emit(false);
    this.router.navigate([this.navigateToRoute]);
  }
  finishSending() {
    this.modal.close();
    if (this.navigateToRoute) {
      this.router.navigate([this.navigateToRoute]);
    } else {
      this.router.navigate(["dashboard/sales"]);
    }
  }
}
