import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { User } from "../auth/model/user";
import { FormBuilder } from "@angular/forms";
import { Component, OnInit } from "@angular/core";
import { AppService } from "../service/app.service";
import { TranslateService } from "@ngx-translate/core";
import { Router, NavigationEnd } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";
import { toggleAnimation } from "../shared/animations";

@Component({
  moduleId: module.id,
  selector: "app-header",
  templateUrl: "./header.html",
  animations: [toggleAnimation],
})
export class HeaderComponent implements OnInit {
  store: any;
  pickerType = false;
  search = false;
  user!: User;
  public datepick$!: Observable<{ from: Date; to: Date | undefined }>;

  cars!: any[];
  user$ = this.storeData.select((d) => d.auth);

  constructor(public translate: TranslateService, public storeData: Store<any>, public router: Router, private appSetting: AppService, private sanitizer: DomSanitizer, public fb: FormBuilder) {
    this.initStore();

    this.user$.subscribe((auth) => {
      this.user = auth.user;
    });
  }

  navigateToCalendar() {
    const currentDate = new Date();
    const month = currentDate.getMonth();
    const year = currentDate.getFullYear();

    this.router.navigate(["/dashboard/calendar"], {
      queryParams: { month: month, year: year },
    });
  }

  async initStore() {
    this.storeData
      .select((d) => d.index)
      .subscribe((d) => {
        this.store = d;
      });
  }

  ngOnInit() {
    this.setActiveDropdown();

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.setActiveDropdown();
      }
    });

    this.cars = [
      { id: false, name: this.translate.instant("filter_by_date") },
      { id: true, name: this.translate.instant("filter_from_date_to_date") },
    ];
  }

  setActiveDropdown() {
    const selector = document.querySelector('ul.horizontal-menu a[routerLink="' + window.location.pathname + '"]');
    if (selector) {
      selector.classList.add("active");
      const all: any = document.querySelectorAll("ul.horizontal-menu .nav-link.active");
      for (let i = 0; all.length && i < all.length; i++) {
        all[i]?.classList.remove("active");
      }
      const ul: any = selector.closest("ul.sub-menu");
      if (ul) {
        let ele: any = ul.closest("li.menu").querySelectorAll(".nav-link");
        if (ele) {
          ele = ele[0];
          setTimeout(() => {
            ele?.classList.add("active");
          });
        }
      }
    }
  }

  changeLanguage(item: any) {
    this.translate.use(item.code);
    this.appSetting.toggleLanguage(item);
    if (this.store.locale?.toLowerCase() === "ae") {
      this.storeData.dispatch({ type: "toggleRTL", payload: "rtl" });
    } else {
      this.storeData.dispatch({ type: "toggleRTL", payload: "ltr" });
    }
    window.location.reload();
  }

  navigateToProfile() {
    this.router.navigate(["/dashobard/user-profile"]);
  }
}
