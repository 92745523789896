import { PopupType } from "../../enums/popup-types";
import { showMessage } from "../../utils/toast.popup";
import { ModalComponent } from "angular-custom-modal";
import { TranslateService } from "@ngx-translate/core";
import { Sale } from "src/app/domain/sales/models/sale";
import { ModalService } from "../../services/modal.service";
import { Travel } from "src/app/domain/travels/models/travel";
import { ETouristService } from "../../services/etourist.service";
import { Customer } from "src/app/domain/customers/models/customer";
import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { TravelDestination } from "src/app/domain/travels/models/travel-destination";

@Component({
  selector: "app-details-dropdown-eturist",
  templateUrl: "./details-dropdown-eturist.component.html",
})
export class DetailsDropdownEturistComponent {
  public planId!: number;
  protected travel!: Travel;
  protected passangers!: Customer[];
  public fileUrl!: string | undefined;
  entityDetails: string | undefined;
  additionalDestinations: TravelDestination[] = [];
  @Input() eTuristRecordId!: string | null;
  @Input() travelId!: number;
  @Input() id!: number;
  @Input() plan!: Sale;
  isLoading = false;
  @Input() emails: string[] = [];
  protected mainDestination!: TravelDestination | undefined;
  @Input() IsGroupETourist!: boolean;
  @Output() recordUpdated = new EventEmitter<void>();
  @Output() formSubmit = new EventEmitter<any>();

  @ViewChild("eturistModal") modal!: ModalComponent;
  @ViewChild("eturistPdfModal") modalPdf!: ModalComponent;
  @ViewChild("eturistUpdateModal") modalUpdate!: ModalComponent;

  isPopupVisible = false;
  constructor(private modalService: ModalService, private eturistService: ETouristService, public translate: TranslateService) {}

  downloadETuristConfirmation() {
    if (this.eTuristRecordId) {
      const subscription = this.eturistService.downloadETuristConfirmation(this.eTuristRecordId).subscribe({
        next: (response) => {
          subscription.unsubscribe();
          if (!response?.documentInfo) {
            console.error("Nedostaje sadržaj dokumenta.");
            return;
          }
          const binaryString = atob(response?.documentInfo.documentContent);
          const byteArray = new Uint8Array(binaryString.length);
          for (let i = 0; i < binaryString.length; i++) {
            byteArray[i] = binaryString.charCodeAt(i);
          }
          const fileBlob = new Blob([byteArray], { type: "application/pdf" });
          this.fileUrl = URL.createObjectURL(fileBlob);
          this.modalPdf.open();
        },
        error: (err) => {
          subscription.unsubscribe();
          showMessage(PopupType.Danger, err.error);
        },
      });
    }
  }

  async deleteETuristConfirmation() {
    const confirmed = await this.modalService.confirm();
    if (confirmed) {
      this.isLoading = true;

      if (this.IsGroupETourist) {
        const deleteSubscription = this.eturistService.deleteGroupTravelPlanFromETurist(this.travelId).subscribe({
          next: () => {
            deleteSubscription.unsubscribe();
            this.isLoading = false;
            this.recordUpdated.emit();
          },
          error: (err) => {
            deleteSubscription.unsubscribe();
            this.isLoading = true;
            this.recordUpdated.emit();
            showMessage(PopupType.Danger, err.error);
          },
        });
      } else {
        const deleteSubscription = this.eturistService.deleteRecordFromETurist(this.travelId).subscribe({
          next: () => {
            deleteSubscription.unsubscribe();
            this.isLoading = false;
            this.recordUpdated.emit();
          },
          error: (err) => {
            deleteSubscription.unsubscribe();
            this.isLoading = true;
            this.recordUpdated.emit();
            showMessage(PopupType.Danger, err.error);
          },
        });
      }
    }
  }
  closeModal(): void {
    this.modalPdf.close();
    this.formSubmit.emit(null);
  }

  updateETuristConfirmation() {
    this.modalUpdate.open();
  }

  toggleFileUploadPopup() {
    this.isPopupVisible = !this.isPopupVisible;
  }
  addedRecordToEturist() {
    this.recordUpdated.emit();
    this.modal.close();
    this.modalPdf.close();
    this.modalUpdate.close();
  }
}
