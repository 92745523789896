<div *ngIf="sale">
  <div class="grid grid-cols-2 gap-5 gap-x-2 sm:grid-cols-5 print:hidden">
    <div class="col-span-1 pt-3">
      <div class="flex-col">
        <div>{{ "sale_details.bruto" | translate }} :</div>
        <div>{{ sale.bruto | currency : "EUR" }}</div>
      </div>
    </div>

    <div class="col-span-1 pt-3">
      <div class="flex-col">
        <div>{{ "sale_details.neto" | translate }} :</div>
        <div>{{ sale.neto | currency : "EUR" }}</div>
      </div>
    </div>

    <div class="col-span-1 pt-3">
      <div class="flex-col">
        <div>{{ "sale_details.avans" | translate }}:</div>
        <div>{{ sale.avans | currency : "EUR" }}</div>
      </div>
    </div>

    <div class="col-span-1 pt-3">
      <div class="flex-col" *ngIf="totalDepth$ | async as totalDepth">
        <div>{{ "sale_details.dept" | translate }}:</div>
        <div>{{ totalDepth | currency : "EUR" }}</div>
      </div>
    </div>

    <div class="col-span-1 pt-3">
      <div class="flex justify-between items-center"> 
        <div class="flex-col">
          <div>{{ "sale_details.paymentType" | translate }}:</div>
          <div *ngIf="sale.paymentType">{{ sale.paymentType.title }}</div>
        </div>
        <a 
          type="button" 
          [ngxTippy]="translate.instant('sale_details.reciept')" 
          class="btn btn-dark h-10 w-10 rounded-full p-0" 
          (click)="sendInvoice()">
          <icon-txt-file></icon-txt-file>
        </a>
      </div>
    </div>
    

    <div class="col-span-2 flex sm:col-span-1" *ngIf="!hideStatus">
      <div class="flex place-content-center items-center self-center">
        <div>{{ "ticket_details.status" | translate }}:</div>
        <ng-container *ngIf="sale.status; else notPaid">
          <div class="badge bg-success">
            {{ "ticket_details.paid" | translate }}
          </div>
        </ng-container>
        <ng-template #notPaid>
          <div class="badge bg-slate-200 text-white">
            {{ "ticket_details.not_paid" | translate }}
          </div>
        </ng-template>
      </div>
    </div>

    <div class="col-span-2 flex sm:col-span-2" *ngIf="!hideStatus">
      <div class="flex place-content-center items-center self-center">
        <div class="ltr:mr-2 rtl:ml-2">{{ "ticket_details.active" | translate }}:</div>
        <div class="badge bg-success" *ngIf="sale.active; else notActive">
          {{ "ticket_details.active" | translate }}
        </div>
        <ng-template #notActive>
          <div class="badge bg-slate-200 text-white">
            {{ "ticket_details.deleted" | translate }}
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
<div class="my-5 print:hidden" id="payments">
  <div *ngIf="!showDocumentPanel">
    <div class="block space-y-4 overflow-x-auto rounded-lg border border-white-dark/20 p-4 print:hidden">
      <div class="text-[20px] text-dark">{{ "sale_details.all_payments" | translate }}:</div>
      <div class="grid grid-cols-1 justify-center sm:grid-cols-1" *ngIf="payments && payments.length > 0">
        <ng-container *ngFor="let item of payments; index as i">
          <div
            [ngClass]="{ 'bg-green-100': editPayment && editPayment.guid === item.guid }"
            class="grid grid-cols-2 items-center justify-between gap-5 gap-x-2 rounded-xl p-3 font-semibold text-gray-500 shadow-[0_0_4px_2px_rgb(31_45_61_/_10%)] transition-all duration-300 hover:scale-[1.01] hover:text-primary dark:bg-[#1b2e4b] sm:min-w-[625px] sm:grid-cols-3"
          >
            <div class="grid-cols-2 px-2 sm:grid-cols-1">{{ "sale_details.payed_at_date" | translate }}: {{ item.paymentDate | date : "dd.MM.yyyy" }}</div>
            <div class="grid-cols-1 px-2 sm:grid-cols-1">
              {{ "sale_details.amound_payed" | translate }}:
              {{ item.payment | currency : "EUR" }}
            </div>
            <div class="flex justify-around">
              <a type="button" class="btn btn-dark h-10 w-10 rounded-full p-0" [ngxTippy]="translate.instant('sale_details.edit')" (click)="toEditPayment(item)">
                <icon-pencil />
              </a>
              <a type="button" [ngxTippy]="translate.instant('sale_details.delete')" class="btn btn-dark h-10 w-10 rounded-full p-0" (click)="removePayment(item)">
                <icon-trash-lines />
              </a>
              <a type="button" [ngxTippy]="translate.instant('sale_details.reciept')" class="btn btn-dark h-10 w-10 rounded-full p-0" (click)="showDocument(item)">
                <icon-txt-file />
              </a>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div *ngIf="editPayment">
      <app-payment-form [payment]="editPayment" [max]="totalDepth$ | async" #appPaymentForm></app-payment-form>

      <div class="grid grid-cols-2 gap-5" *ngIf="editPayment.id">
        <button (click)="discard()" class="btn btn-outline-warning w-full">{{ "sale_details.payment_form_clear" | translate }}</button>
        <button (click)="submitPayment()" class="btn btn-outline-primary w-full">{{ "sale_details.payment_form_submit" | translate }}</button>
      </div>
      <div class="grid grid-cols-2 gap-5" *ngIf="!editPayment.id">
        <button (click)="discard()" class="btn btn-outline-warning w-full">{{ "sale_details.payment_form_clear" | translate }}</button>
        <button (click)="submitNewPayment()" class="btn btn-outline-primary w-full">{{ "sale_details.payment_form_submit" | translate }}</button>
      </div>
    </div>
    <div *ngIf="sale && !editPayment && !sale.status">
      <button (click)="setNewPayment()" class="btn btn-outline-primary w-full">{{ "sale_details.add_new_payment" | translate }}</button>
    </div>
  </div>
</div>

<div class="mb-6 flex flex-wrap items-center justify-center gap-4 lg:justify-end print:hidden" *ngIf="showDocumentPanel">
  <button type="button" class="btn btn-info gap-2" (click)="sendPayment()">
    <icon-send />
    {{ "paymentdetails.send" | translate }}
  </button>

  <button type="button" class="btn btn-primary gap-2" (click)="printPayment()">
    <icon-printer />
    {{ "paymentdetails.print" | translate }}
  </button>
</div>

<div class="my-5 grid grid-cols-1 gap-5 sm:grid-cols-2" *ngIf="showDocumentPanel">
  <div class="block space-y-4 overflow-x-auto rounded-lg border border-white-dark/20 p-4 print:hidden">
    <div class="text-[20px] text-dark">{{ "sale_details.all_payments" | translate }}:</div>
    <div class="grid grid-cols-1 justify-center sm:grid-cols-1" *ngIf="payments && payments.length > 0">
      <ng-container *ngFor="let item of payments; index as i">
        <div
          [ngClass]="{ 'bg-green-100': editPayment && editPayment.guid === item.guid }"
          class="grid grid-cols-2 items-center justify-between gap-5 gap-x-2 rounded-xl p-3 font-semibold text-gray-500 shadow-[0_0_4px_2px_rgb(31_45_61_/_10%)] transition-all duration-300 hover:scale-[1.01] hover:text-primary dark:bg-[#1b2e4b] sm:min-w-[625px] sm:grid-cols-3"
        >
          <div class="grid-cols-2 px-2 sm:grid-cols-1">{{ "sale_details.payed_at_date" | translate }}: {{ item.paymentDate | date : "dd.MM.yyyy" }}</div>
          <div class="grid-cols-1 px-2 sm:grid-cols-1">
            {{ "sale_details.amound_payed" | translate }}:
            {{ item.payment | currency : "EUR" }}
          </div>
          <div class="flex justify-around">
            <a type="button" class="btn btn-dark h-10 w-10 rounded-full p-0" [ngxTippy]="translate.instant('sale_details.edit')" (click)="toEditPayment(item)">
              <icon-pencil />
            </a>
            <a type="button" [ngxTippy]="translate.instant('sale_details.delete')" class="btn btn-dark h-10 w-10 rounded-full p-0" (click)="removePayment(item)">
              <icon-trash-lines />
            </a>
            <a type="button" [ngxTippy]="translate.instant('sale_details.reciept')" class="btn btn-dark h-10 w-10 rounded-full p-0" (click)="showDocument(item)">
              <icon-txt-file />
            </a>
          </div>
        </div>
      </ng-container>
    </div>

    <div *ngIf="editPayment">
      <app-payment-form [payment]="editPayment" [max]="totalDepth$ | async" #appPaymentForm></app-payment-form>

      <div class="grid grid-cols-2 gap-5" *ngIf="editPayment.id">
        <button (click)="discard()" class="btn btn-outline-warning w-full">{{ "sale_details.payment_form_clear" | translate }}</button>
        <button (click)="submitPayment()" class="btn btn-outline-primary w-full">{{ "sale_details.payment_form_submit" | translate }}</button>
      </div>
      <div class="grid grid-cols-2 gap-5" *ngIf="!editPayment.id">
        <button (click)="discard()" class="btn btn-outline-warning w-full">{{ "sale_details.payment_form_clear" | translate }}</button>
        <button (click)="submitNewPayment()" class="btn btn-outline-primary w-full">{{ "sale_details.payment_form_submit" | translate }}</button>
      </div>
    </div>
    <div *ngIf="sale && !editPayment && !sale.status">
      <button (click)="setNewPayment()" class="btn btn-outline-primary w-full">{{ "sale_details.add_new_payment" | translate }}</button>
    </div>
  </div>

  <app-payment-document [payments]="payments" [showDocumentPanel]="showDocumentPanel" [paymentDocument]="paymentDocument"></app-payment-document>
</div>

<modal #modal class="modal-top custom-modal">
  <ng-template #modalBody>
    <app-file-send
      [title]="'payment_recipet_form_title' | translate"
      fileType="payment"
      [passangers]="[sale.customer]"
      [sendDocumentFn]="saleService.sendPayment(this.paymentDocument?.id ?? 0)"
      [messageRs]="messageRS"
      [messageEn]="messageEN"
      (notifyWhenSend)="finishSending()"
    >
      <h2 class="mb-4 text-center">{{ "paymentssendemail.paymentdetails" | translate }}</h2>
      <label for="" class="mb-1 block">{{ "paymentssendemail.passengers" | translate }}:</label>
      <table class="mb-4 w-full table-auto">
        <thead>
          <tr>
            <th class="px-4 py-2">{{ "paymentssendemail.name" | translate }}</th>
            <th class="px-4 py-2">{{ "paymentssendemail.email" | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="border px-4 py-2">{{ this.paymentDocument?.customerFullName }}</td>
            <td class="border px-4 py-2">{{ this.paymentDocument?.customerEmail }}</td>
          </tr>
        </tbody>
      </table>
      <table class="table-responsive mb-3 w-full border-gray-400">
        <thead class="border-top: 2px">
          <tr class="ng-star-inserted col-span-1 border border-gray-300 bg-gray-100 sm:col-span-3">
            <th class="border border-gray-300 sm:col-span-1">{{ "paymentssendemail.createdate" | translate }}</th>
            <th class="border border-gray-300 sm:col-span-1">{{ "paymentssendemail.paymentdate" | translate }}</th>
            <th class="border border-gray-300 sm:col-span-1">{{ "paymentssendemail.updatedate" | translate }}</th>
            <th class="border border-gray-300 sm:col-span-1">{{ "paymentssendemail.traveltitle" | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr class="ng-star-inserted border border-gray-300 bg-gray-50">
            <td class="border border-gray-300">{{ this.paymentDocument?.createdDate | date : "dd.MM.yyyy" }}</td>
            <td class="border border-gray-300">{{ this.paymentDocument?.paymentDate | date : "dd.MM.yyyy" }}</td>
            <td class="border border-gray-300">{{ this.paymentDocument?.updatedDate | date : "dd.MM.yyyy" }}</td>
            <td class="border border-gray-300">{{ this.paymentDocument?.travelTitle }}</td>
          </tr>
        </tbody>

        <thead class="border-top: 2px">
          <tr class="ng-star-inserted border border-gray-300 bg-gray-100">
            <th class="border border-gray-300">{{ "paymentssendemail.currentpayment" | translate }}</th>
            <th class="border border-gray-300">{{ "paymentssendemail.totalprice" | translate }}</th>
            <th class="border border-gray-300">{{ "paymentssendemail.avans" | translate }}</th>
            <th class="border border-gray-300">{{ "paymentssendemail.toBePaid" | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr class="ng-star-inserted border border-gray-300 bg-gray-50">
            <td class="border border-gray-300">{{ this.paymentDocument?.currentPayment | currency : "EUR" : "symbol" : "1.2-2" }}</td>
            <td class="border border-gray-300">{{ this.paymentDocument?.totalPrice | currency : "EUR" : "symbol" : "1.2-2" }}</td>
            <td class="border border-gray-300">{{ this.paymentDocument?.avans | currency : "EUR" : "symbol" : "1.3-3" }}</td>
            <td class="border border-gray-300">{{ this.paymentDocument?.toBePaid | currency : "EUR" : "symbol" : "1.2-2" }}</td>
          </tr>
        </tbody>
      </table>
    </app-file-send>
  </ng-template>
</modal>
