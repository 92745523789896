<div *ngIf="user">
  <form class="mb-5 rounded-md border border-[#ebedf2] bg-white p-4 dark:border-[#191e3a] dark:bg-[#0e1726]" [formGroup]="userForm" (ngSubmit)="submitUserForm()">
    <h6 class="mb-5 text-lg font-bold">{{ "user_profile.general_information" | translate }}</h6>
    <div class="flex flex-col sm:flex-row">
      <div class="mb-5 w-full sm:w-2/12 ltr:sm:mr-4 rtl:sm:ml-4">
        <img [src]="user.imageUrl ?? '/assets/images/auth/user-icon.png'" alt="" class="mx-auto h-20 w-20 rounded-full object-cover md:h-32 md:w-32" />
      </div>
      <div class="grid flex-1 grid-cols-1 gap-5 sm:grid-cols-2">
        <div [ngClass]="userForm.controls['first_name'].touched ? (userForm.controls['first_name'].errors ? 'has-error' : 'has-success') : ''">
          <label for="name">{{ "user_profile.first_name" | translate }}</label>
          <input
            id="name"
            type="text"
            [ngClass]="!editProfile ? 'form-input cursor-not-allowed disabled:pointer-events-none disabled:bg-[#eee] dark:disabled:bg-[#1b2e4b]' : 'form-input'"
            formControlName="first_name"
          />
          <ng-container *ngIf="userForm.controls['first_name'].touched && userForm.controls['first_name'].errors">
            <p class="mt-1 text-danger">
              <small>{{ "user_profile.please_provide_name" | translate }}</small>
            </p>
          </ng-container>
        </div>
        <div [ngClass]="userForm.controls['last_name'].touched ? (userForm.controls['last_name'].errors ? 'has-error' : 'has-success') : ''">
          <label for="surname">{{ "user_profile.last_name" | translate }}</label>
          <input
            id="surname"
            type="text"
            [ngClass]="!editProfile ? 'form-input cursor-not-allowed disabled:pointer-events-none disabled:bg-[#eee] dark:disabled:bg-[#1b2e4b]' : 'form-input'"
            formControlName="last_name"
          />
          <ng-container *ngIf="userForm.controls['last_name'].touched && userForm.controls['last_name'].errors">
            <p class="mt-1 text-danger">
              <small>{{ "user_profile.please_provide_surname" | translate }}</small>
            </p>
          </ng-container>
        </div>
        <div>
          <label for="email">{{ "user_profile.email" | translate }}</label>
          <input id="email" type="text" class="form-input" class="form-input cursor-not-allowed disabled:pointer-events-none disabled:bg-[#eee] dark:disabled:bg-[#1b2e4b]" formControlName="email" />
        </div>
        <div>
          <label for="role">{{ "user_profile.role" | translate }}</label>
          <input id="role" type="text" class="form-input cursor-not-allowed disabled:pointer-events-none disabled:bg-[#eee] dark:disabled:bg-[#1b2e4b]" formControlName="role" />
        </div>
        <div [ngClass]="userForm.controls['uniqueMasterCitizenNumber'].touched ? (userForm.controls['uniqueMasterCitizenNumber'].errors ? 'has-error' : 'has-success') : ''">
          <label for="uniqueMasterCitizenNumber">{{ "user_profile.uniqueMasterCitizenNumber" | translate }}</label>
          <input
            id="uniqueMasterCitizenNumber"
            type="text"
            [ngClass]="!editProfile ? 'form-input cursor-not-allowed disabled:pointer-events-none disabled:bg-[#eee] dark:disabled:bg-[#1b2e4b]' : 'form-input'"
            formControlName="uniqueMasterCitizenNumber"
          />
          <ng-container *ngIf="userForm.controls['uniqueMasterCitizenNumber'].touched && userForm.controls['uniqueMasterCitizenNumber'].errors">
            <p class="mt-1 text-danger">
              <small>{{ "user_profile.please_provide_uniqueMasterCitizenNumber" | translate }}</small>
            </p>
          </ng-container>
        </div>
        <div></div>
        <div class="flex justify-between">
          <div *ngIf="editProfile">
            <button type="submit" class="btn btn-success">{{ "user_profile.save" | translate }}</button>
          </div>
          <div *ngIf="!editProfile">
            <button type="button" class="btn btn-warning" (click)="editUser()">{{ "user_profile.edit" | translate }}</button>
          </div>
          <div class="flex justify-end">
            <button type="button" class="btn btn-primary" (click)="modal.open()">{{ "user_profile.change_password" | translate }}</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<!-- Modal -->
<modal #modal class="modal-center auth-modal">
  <ng-template #modalHeader>
    <div class="!py-5"></div>
  </ng-template>
  <ng-template #modalBody>
    <app-user-new-password [email]="user.email" (notifyWhenSend)="passwordUpdated($event)"></app-user-new-password>
  </ng-template>
</modal>
