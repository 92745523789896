import {
  Injectable,
  Injector,
  ComponentFactoryResolver,
  ApplicationRef,
  ComponentRef,
} from "@angular/core";
import { ConfirmationDialogComponent } from "../components/confirmation-dialog/confirmation-dialog.component";
import { TranslateService } from "@ngx-translate/core";
import { CustomerCreateEditComponent } from "src/app/domain/customers/customer-create-edit/customer-create-edit.component";
import { CustomersService } from "src/app/domain/customers/services/customers.service";

import { HotelsService } from "src/app/domain/hotels/services/hotels.service";
import { firstValueFrom, Subscription } from 'rxjs';
import { CompaniesService } from "src/app/domain/companies/services/companies.service";
import { OrganizersService } from "src/app/domain/organizers/services/organizers.service";
import { OrganizerFormComponent } from "src/app/domain/organizers/organizer-form/organizer-form.component";
import { Organizer } from "src/app/domain/organizers/models/organizers";
import { take } from 'rxjs/operators';
import { EditDestinationComponent } from "src/app/domain/destinations/edit-destination/edit-destination.component";
import { Destination } from "src/app/domain/destinations/models/destination";
import { DestinationsService } from "src/app/domain/destinations/services/destinations.service";
import { WorkerFormComponent } from "src/app/domain/workers/worker.form.component/worker.form.component";
import { WorkersService } from "src/app/domain/workers/services/workers.service";
import { CompanyFormComponent } from "src/app/domain/companies/company-form/company-form.component";
import { HotelFormComponent } from "src/app/domain/hotels/hotel-form/hotel-form.component";
import { InvoiceComponent } from "../components/invoice/invoice.component";
import { SaleBase } from "src/app/domain/sales/models/sale";

interface ConfirmationDialogOptions {
  title?: string;
  message?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
}

interface InvoiceDialogOptions {
  title?: string;
  message?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  plan: SaleBase;
  navigateToRoute: string;
}

@Injectable({
  providedIn: "root",
})
export class ModalService {
  private isHotelEditModalOpen = false;
  constructor(
    private injector: Injector,
    private resolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private translateService: TranslateService,
    private customersService: CustomersService,
    private hotelsService: HotelsService,
    private companiesService: CompaniesService,
    private organizersService: OrganizersService,
    private destinationsService: DestinationsService,
    private workersService: WorkersService
  ) { }


  /**
   * Opens a confirmation dialog.
   * @param options Customization options for the confirmation dialog.
   * @returns Promise<boolean> indicating user confirmation.
   */
  
  confirm(options: ConfirmationDialogOptions = {}): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const componentFactory = this.resolver.resolveComponentFactory(ConfirmationDialogComponent);
      const componentRef = componentFactory.create(this.injector);

      // Set the component properties
      componentRef.instance.title = options.title || this.translateService.instant('modal.confirmation');
      componentRef.instance.message = options.message || this.translateService.instant('modal.are_you_sure_you_want_to_proceed');
      componentRef.instance.confirmButtonText = options.confirmButtonText || this.translateService.instant('modal.confirm');
      componentRef.instance.cancelButtonText = options.cancelButtonText || this.translateService.instant('modal.cancel');

      componentRef.instance.confirmed.subscribe((confirmed: boolean) => {
        resolve(confirmed);
        this.closeModal(componentRef, null, null, onEscape, onBackdropClick);
      });

      // Define event handlers
      const backdrop = this.attachBackdrop();
      const onBackdropClick = () => {
        this.closeModal(componentRef, backdrop, null, onEscape, onBackdropClick);
        resolve(false);
      };

      const onEscape = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          this.closeModal(componentRef, backdrop, null, onEscape, onBackdropClick);
          resolve(false);
        }
      };

      backdrop.addEventListener('click', onBackdropClick);
      document.addEventListener('keydown', onEscape);

      this.attachComponentToDOM(componentRef);
    });
  }


  openWorkerAddModal(): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      try {
        const componentFactory = this.resolver.resolveComponentFactory(WorkerFormComponent);
        const componentRef: ComponentRef<WorkerFormComponent> = componentFactory.create(this.injector);

        this.attachComponentToDOM(componentRef);
        const backdrop = this.attachBackdrop();

        let isSubmitted = false;

        const onEscape = (event: KeyboardEvent) => {
          if (event.key === 'Escape') {
            this.closeModal(componentRef, backdrop, subscription, onEscape, onBackdropClick);
            resolve(false);
          }
        };

        const onBackdropClick = () => {
          this.closeModal(componentRef, backdrop, subscription, onEscape, onBackdropClick);
          resolve(false);
        };

        backdrop.addEventListener('click', onBackdropClick);
        document.addEventListener('keydown', onEscape);

        const subscription: Subscription = componentRef.instance.submissionDone
          .pipe(take(1))
          .subscribe(async (data: any) => {
            if (data && !isSubmitted) {
              isSubmitted = true;
              try {
                await firstValueFrom(this.workersService.createWorker(data));
                this.closeModal(componentRef, backdrop, subscription, onEscape, onBackdropClick);
                resolve(true);
              } catch (error) {
                isSubmitted = false;
              }
            } else {
              this.closeModal(componentRef, backdrop, subscription, onEscape, onBackdropClick);
              resolve(false);
            }
          });

      } catch (error) {
        reject(error);
      }
    });
  }

  openWorkerEditModal(workerId: number): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      try {
        const componentFactory = this.resolver.resolveComponentFactory(WorkerFormComponent);
        const componentRef: ComponentRef<WorkerFormComponent> = componentFactory.create(this.injector);

        componentRef.instance.id = workerId;
        this.attachComponentToDOM(componentRef);
        const backdrop = this.attachBackdrop();

        let isSubmitted = false;

        const onEscape = (event: KeyboardEvent) => {
          if (event.key === 'Escape') {
            this.closeModal(componentRef, backdrop, subscription, onEscape, onBackdropClick);
            resolve(false);
          }
        };

        const onBackdropClick = () => {
          this.closeModal(componentRef, backdrop, subscription, onEscape, onBackdropClick);
          resolve(false);
        };

        backdrop.addEventListener('click', onBackdropClick);
        document.addEventListener('keydown', onEscape);

        const subscription: Subscription = componentRef.instance.submissionDone
          .pipe(take(1))
          .subscribe(async (data: any) => {
            if (data && !isSubmitted) {
              isSubmitted = true;
              try {
                await firstValueFrom(this.workersService.updateWorker(workerId, data));
                this.closeModal(componentRef, backdrop, subscription, onEscape, onBackdropClick);
                resolve(true);
              } catch (error) {
                isSubmitted = false;
              }
            } else {
              this.closeModal(componentRef, backdrop, subscription, onEscape, onBackdropClick);
              resolve(false);
            }
          });

      } catch (error) {
        reject(error);
      }
    });
  }

  openDestinationAddModal(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      try {
        const componentFactory = this.resolver.resolveComponentFactory(EditDestinationComponent);
        const componentRef: ComponentRef<EditDestinationComponent> = componentFactory.create(this.injector);

        componentRef.instance.editMode = false;
        componentRef.instance.destination = {} as Destination;
        componentRef.instance.isLoading = false;

        this.attachComponentToDOM(componentRef);
        const backdrop = this.attachBackdrop();

        let isSubmitted = false;

        const onEscape = (event: KeyboardEvent) => {
          if (event.key === 'Escape') {
            this.closeModal(componentRef, backdrop, subscription, onEscape, onBackdropClick);
            resolve(false);
          }
        };

        const onBackdropClick = () => {
          this.closeModal(componentRef, backdrop, subscription, onEscape, onBackdropClick);
          resolve(false);
        };

        backdrop.addEventListener('click', onBackdropClick);
        document.addEventListener('keydown', onEscape);

        const subscription: Subscription = componentRef.instance.formSubmit.subscribe(async (data: any) => {
          if (data && !isSubmitted) {
            isSubmitted = true;
            try {
              await firstValueFrom(this.destinationsService.addDestination(data));
              this.closeModal(componentRef, backdrop, subscription, onEscape, onBackdropClick);
              resolve(true);
            } catch (error) {
              isSubmitted = false;
            }
          } else {
            this.closeModal(componentRef, backdrop, subscription, onEscape, onBackdropClick);
            resolve(false);
          }
        });

      } catch (error) {
        reject(error);
      }
    });
  }

  openDestinationEditModal(destination: Destination): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      try {
        const componentFactory = this.resolver.resolveComponentFactory(EditDestinationComponent);
        const componentRef: ComponentRef<EditDestinationComponent> = componentFactory.create(this.injector);

        componentRef.instance.editMode = true;
        componentRef.instance.destination = destination;
        componentRef.instance.isLoading = false;

        this.attachComponentToDOM(componentRef);
        const backdrop = this.attachBackdrop();

        let isSubmitted = false;

        const onEscape = (event: KeyboardEvent) => {
          if (event.key === 'Escape') {
            this.closeModal(componentRef, backdrop, subscription, onEscape, onBackdropClick);
            resolve(false);
          }
        };

        const onBackdropClick = () => {
          this.closeModal(componentRef, backdrop, subscription, onEscape, onBackdropClick);
          resolve(false);
        };

        backdrop.addEventListener('click', onBackdropClick);
        document.addEventListener('keydown', onEscape);

        const subscription: Subscription = componentRef.instance.formSubmit.subscribe(async (data: any) => {
          if (data && !isSubmitted) {
            isSubmitted = true;
            try {
              await firstValueFrom(this.destinationsService.updateDestination(destination.id, data));
              this.closeModal(componentRef, backdrop, subscription, onEscape, onBackdropClick);
              resolve(true);
            } catch (error) {
              isSubmitted = false;
            }
          } else {
            this.closeModal(componentRef, backdrop, subscription, onEscape, onBackdropClick);
            resolve(false);
          }
        });

      } catch (error) {
        reject(error);
      }
    });
  }


  openOrganizerAddModal(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      try {
        const componentFactory = this.resolver.resolveComponentFactory(OrganizerFormComponent);
        const componentRef: ComponentRef<OrganizerFormComponent> = componentFactory.create(this.injector);

        componentRef.instance.editMode = false;
        componentRef.instance.organizerData = null;
        componentRef.instance.isLoading = false;

        this.attachComponentToDOM(componentRef);
        const backdrop = this.attachBackdrop();

        let isSubmitted = false;

        const onEscape = (event: KeyboardEvent) => {
          if (event.key === 'Escape') {
            this.closeModal(componentRef, backdrop, subscription, onEscape, onBackdropClick);
            resolve(false);
          }
        };

        const onBackdropClick = () => {
          this.closeModal(componentRef, backdrop, subscription, onEscape, onBackdropClick);
          resolve(false);
        };

        backdrop.addEventListener('click', onBackdropClick);
        document.addEventListener('keydown', onEscape);

        const subscription: Subscription = componentRef.instance.formSubmit.subscribe(async (data: any) => {
          if (data && !isSubmitted) {
            isSubmitted = true;
            try {
              await firstValueFrom(this.organizersService.addOrganizer(data));
              this.closeModal(componentRef, backdrop, subscription, onEscape, onBackdropClick);
              resolve(true);
            } catch (error) {
              isSubmitted = false;
            }
          } else {
            this.closeModal(componentRef, backdrop, subscription, onEscape, onBackdropClick);
            resolve(false);
          }
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  openOrganizerEditModal(organizerData: Organizer): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const componentFactory = this.resolver.resolveComponentFactory(OrganizerFormComponent);
      const componentRef: ComponentRef<OrganizerFormComponent> = componentFactory.create(this.injector);

      componentRef.instance.editMode = true;
      componentRef.instance.organizerData = organizerData;
      componentRef.instance.isLoading = false;

      this.attachComponentToDOM(componentRef);
      const backdrop = this.attachBackdrop();

      let isSubmitted = false;

      const onEscape = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          this.closeModal(componentRef, backdrop, subscription, onEscape, onBackdropClick);
          resolve(false);
        }
      };

      const onBackdropClick = () => {
        this.closeModal(componentRef, backdrop, subscription, onEscape, onBackdropClick);
        resolve(false);
      };

      backdrop.addEventListener('click', onBackdropClick);
      document.addEventListener('keydown', onEscape);

      const subscription: Subscription = componentRef.instance.formSubmit.subscribe(async (data: Organizer | null) => {
        if (data && !isSubmitted) {
          isSubmitted = true;
          try {
            await firstValueFrom(this.organizersService.updateOrganizer(data, organizerData.id));
            this.closeModal(componentRef, backdrop, subscription, onEscape, onBackdropClick);
            resolve(true);
          } catch (error) {
            isSubmitted = false;
          }
        } else {
          this.closeModal(componentRef, backdrop, subscription, onEscape, onBackdropClick);
          resolve(false);
        }
      });
    });
  }

  openCompanyAddModal(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const componentFactory = this.resolver.resolveComponentFactory(CompanyFormComponent);
      const componentRef: ComponentRef<CompanyFormComponent> = componentFactory.create(this.injector);

      componentRef.instance.editMode = false;
      componentRef.instance.entityData = {};
      componentRef.instance.isLoading = false;

      this.attachComponentToDOM(componentRef);
      const backdrop = this.attachBackdrop();

      let isSubmitted = false;

      const onEscape = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          this.closeModal(componentRef, backdrop, subscription, onEscape, onBackdropClick);
          resolve(false);
        }
      };

      const onBackdropClick = () => {
        this.closeModal(componentRef, backdrop, subscription, onEscape, onBackdropClick);
        resolve(false);
      };

      backdrop.addEventListener('click', onBackdropClick);
      document.addEventListener('keydown', onEscape);

      const subscription: Subscription = componentRef.instance.formSubmit.subscribe(async (data: any) => {
        if (data && !isSubmitted) {
          isSubmitted = true;
          try {
            await firstValueFrom(this.companiesService.addCompany(data));
            this.closeModal(componentRef, backdrop, subscription, onEscape, onBackdropClick);
            resolve(true);
          } catch (error) {
            isSubmitted = false;
          }
        } else {
          this.closeModal(componentRef, backdrop, subscription, onEscape, onBackdropClick);
          resolve(false);
        }
      });
    });
  }

  openCompanyEditModal(companyId: number): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      try {
        const companyData = await firstValueFrom(this.companiesService.getCompanyStatistics(companyId));

        const componentFactory = this.resolver.resolveComponentFactory(CompanyFormComponent);
        const componentRef: ComponentRef<CompanyFormComponent> = componentFactory.create(this.injector);

        componentRef.instance.editMode = true;
        componentRef.instance.entityData = companyData;
        componentRef.instance.isLoading = false;

        this.attachComponentToDOM(componentRef);
        const backdrop = this.attachBackdrop();

        let isSubmitted = false;

        const onEscape = (event: KeyboardEvent) => {
          if (event.key === 'Escape') {
            this.closeModal(componentRef, backdrop, subscription, onEscape, onBackdropClick);
            resolve(false);
          }
        };

        const onBackdropClick = () => {
          this.closeModal(componentRef, backdrop, subscription, onEscape, onBackdropClick);
          resolve(false);
        };

        backdrop.addEventListener('click', onBackdropClick);
        document.addEventListener('keydown', onEscape);

        const subscription: Subscription = componentRef.instance.formSubmit.subscribe(async (data: any) => {
          if (data && !isSubmitted) {
            isSubmitted = true;
            try {
              await firstValueFrom(this.companiesService.updateCompany(companyId, data));
              this.closeModal(componentRef, backdrop, subscription, onEscape, onBackdropClick);
              resolve(true);
            } catch (error) {
              isSubmitted = false;
            }
          } else {
            this.closeModal(componentRef, backdrop, subscription, onEscape, onBackdropClick);
            resolve(false);
          }
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  openHotelEditModal(hotelId: number): Promise<boolean> {
    if (this.isHotelEditModalOpen) {
      return Promise.reject("Modal already open");
    }

    this.isHotelEditModalOpen = true;

    return new Promise<boolean>(async (resolve, reject) => {
      try {
        const hotelData = await firstValueFrom(this.hotelsService.getHotelStatistics(hotelId));

        const componentFactory = this.resolver.resolveComponentFactory(HotelFormComponent);
        const componentRef: ComponentRef<HotelFormComponent> = componentFactory.create(this.injector);

        componentRef.instance.editMode = true;
        componentRef.instance.isLoading = true;
        componentRef.instance.entityData = hotelData;
        componentRef.instance.isLoading = false;

        this.attachComponentToDOM(componentRef);
        const backdrop = this.attachBackdrop();

        const onEscape = (event: KeyboardEvent) => {
          if (event.key === "Escape") {
            this.closeModal(componentRef, backdrop, subscription, onEscape, onBackdropClick);
            resolve(false);
          }
        };

        const onBackdropClick = () => {
          this.closeModal(componentRef, backdrop, subscription, onEscape, onBackdropClick);
          resolve(false);
        };

        backdrop.addEventListener("click", onBackdropClick);
        document.addEventListener("keydown", onEscape);

        let isSubmitted = false;

        const subscription = componentRef.instance.formSubmit
          .pipe(take(1))
          .subscribe(async (updatedHotelData) => {
            if (updatedHotelData !== null && !isSubmitted) {
              isSubmitted = true;
              try {
                await firstValueFrom(this.hotelsService.updateHotel(hotelId, updatedHotelData));
                this.closeModal(componentRef, backdrop, subscription, onEscape, onBackdropClick);
                resolve(true);
              } catch (error) {
                isSubmitted = false;
              }
            } else {
              this.closeModal(componentRef, backdrop, subscription, onEscape, onBackdropClick);
              resolve(false);
            }
          });

      } catch (error) {
        this.isHotelEditModalOpen = false;
        resolve(false);
      }
    });
  }

  openHotelAddModal(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const componentFactory = this.resolver.resolveComponentFactory(HotelFormComponent);
      const componentRef: ComponentRef<HotelFormComponent> = componentFactory.create(this.injector);

      componentRef.instance.editMode = false;
      componentRef.instance.entityData = {};
      componentRef.instance.isLoading = false;

      let isSubmitted = false;

      const backdrop = this.attachBackdrop();
      const onBackdropClick = () => {
        this.closeModal(componentRef, backdrop, subscription, onEscape, onBackdropClick);
        resolve(false);
      };

      const onEscape = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          this.closeModal(componentRef, backdrop, subscription, onEscape, onBackdropClick);
          resolve(false);
        }
      };

      backdrop.addEventListener('click', onBackdropClick);
      document.addEventListener('keydown', onEscape);

      const subscription = componentRef.instance.formSubmit.subscribe(async (hotelData) => {
        if (hotelData !== null && !isSubmitted) {
          isSubmitted = true;
          try {
            await firstValueFrom(this.hotelsService.addHotel(hotelData));
            this.closeModal(componentRef, backdrop, subscription, onEscape, onBackdropClick);
            resolve(true);
          } catch (error) {
            isSubmitted = false;
          }
        } else {
          this.closeModal(componentRef, backdrop, subscription, onEscape, onBackdropClick);
          resolve(false);
        }
      });

      this.attachComponentToDOM(componentRef);

    });
  }

  openCustomerEditModal(customerId: number): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const componentFactory = this.resolver.resolveComponentFactory(CustomerCreateEditComponent);
      const componentRef = componentFactory.create(this.injector);

      componentRef.instance.customerId = customerId;

      let isSubmitted = false;

      const backdrop = document.createElement('div');
      backdrop.className = 'fixed inset-0 bg-black bg-opacity-50 z-40';
      document.body.appendChild(backdrop);

      const onEscape = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          this.closeModal(componentRef, backdrop, subscription, onEscape, onBackdropClick);
          resolve(false);
        }
      };

      const onBackdropClick = () => {
        this.closeModal(componentRef, backdrop, subscription, onEscape, onBackdropClick);
        resolve(false);
      };

      const subscription = componentRef.instance.formSubmit.subscribe(async (customerData) => {
        if (customerData !== null && !isSubmitted) {
          isSubmitted = true;
          try {
            await firstValueFrom(this.customersService.updateCustomerData(customerId, customerData));
            this.closeModal(componentRef, backdrop, subscription, onEscape, onBackdropClick);
            resolve(true);
          } catch (error) {
            isSubmitted = false;
          }
        } else {
          this.closeModal(componentRef, backdrop, subscription, onEscape, onBackdropClick);
          resolve(false);
        }
      });

      this.appRef.attachView(componentRef.hostView);
      const domElem = (componentRef.hostView as any).rootNodes[0] as HTMLElement;
      document.body.appendChild(domElem);

      backdrop.addEventListener('click', onBackdropClick);
      document.addEventListener('keydown', onEscape);
    });
  }

  openCustomerAddModal(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const componentFactory = this.resolver.resolveComponentFactory(CustomerCreateEditComponent);
      const componentRef = componentFactory.create(this.injector);

      componentRef.instance.editMode = false;

      const backdrop = document.createElement('div');
      backdrop.className = 'fixed inset-0 bg-black bg-opacity-50 z-40';
      document.body.appendChild(backdrop);

      let isSubmitted = false;

      const subscription = componentRef.instance.formSubmit.subscribe(async (customerData) => {
        if (customerData !== null && !isSubmitted) {
          isSubmitted = true;
          try {
            await firstValueFrom(this.customersService.createCustomerData(customerData));
            this.closeModal(componentRef, backdrop, subscription, onEscape, onBackdropClick);
            resolve(true);
          } catch (error) {
            isSubmitted = false;
          }
        } else {
          this.closeModal(componentRef, backdrop, subscription, onEscape, onBackdropClick);
          resolve(false);
        }
      });

      this.appRef.attachView(componentRef.hostView);
      const domElem = (componentRef.hostView as any).rootNodes[0] as HTMLElement;
      document.body.appendChild(domElem);

      const onBackdropClick = () => {
        this.closeModal(componentRef, backdrop, subscription, onEscape, onBackdropClick);
        resolve(false);
      };
      backdrop.addEventListener('click', onBackdropClick);

      const onEscape = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          this.closeModal(componentRef, backdrop, subscription, onEscape, onBackdropClick);
          resolve(false);
        }
      };
      document.addEventListener('keydown', onEscape);
    });
  }

  private attachComponentToDOM(componentRef: ComponentRef<any>): void {
    this.appRef.attachView(componentRef.hostView);
    const domElem = (componentRef.hostView as any).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);
  }

  private attachBackdrop(): HTMLElement {
    const backdrop = document.createElement('div');
    backdrop.className = 'fixed inset-0 bg-black bg-opacity-50 z-40';
    document.body.appendChild(backdrop);
    return backdrop;
  }

  public closeModal(
    componentRef: ComponentRef<any>,
    backdrop: HTMLElement | null,
    subscription: any,
    onEscape: (event: KeyboardEvent) => void,
    onBackdropClick: () => void
  ): void {
    if (subscription) {
      subscription.unsubscribe();
    }

    if (backdrop) {
      backdrop.removeEventListener('click', onBackdropClick);
      document.body.removeChild(backdrop);
    }

    if (onEscape) {
      document.removeEventListener('keydown', onEscape);
    }

    this.appRef.detachView(componentRef.hostView);
    componentRef.destroy();
  }
  
  openModalForSendingInovice(options: InvoiceDialogOptions): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const componentFactory = this.resolver.resolveComponentFactory(InvoiceComponent);
      const componentRef = componentFactory.create(this.injector);

      componentRef.instance.title = options.title || this.translateService.instant("modal.confirmation");
      componentRef.instance.message = options.message || this.translateService.instant("modal.are_you_sure_you_want_to_proceed");
      componentRef.instance.confirmButtonText = options.confirmButtonText || this.translateService.instant("modal.confirm");
      componentRef.instance.cancelButtonText = options.cancelButtonText || this.translateService.instant("modal.cancel");

      componentRef.instance.plan = options.plan;
      componentRef.instance.navigateToRoute = options.navigateToRoute;

      componentRef.instance.confirmed.subscribe((confirmed: boolean) => {
        resolve(confirmed);
        this.appRef.detachView(componentRef.hostView);
        componentRef.destroy();
      });
      
      this.appRef.attachView(componentRef.hostView);
      const domElem = (componentRef.hostView as any).rootNodes[0] as HTMLElement;
      document.body.appendChild(domElem);
    });
  }
}
