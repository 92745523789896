import { Sale } from "../models/sale";
import { Payment } from "../models/payment";
import { ModalComponent } from "angular-custom-modal";
import { TranslateService } from "@ngx-translate/core";
import { SalesService } from "../services/sales.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { PaymentService } from "../services/payment.service";
import { PaymentDocument } from "../models/payment-document";
import { BehaviorSubject, combineLatest, map, Observable, Subscription } from "rxjs";
import { DocumentService } from "src/app/shared/services/documents.service";
import { PaymentFormComponent } from "../payment.form/payment.form.component";
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { toggleAnimation } from "src/app/shared/animations";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { PopupType } from "src/app/shared/enums/popup-types";
import { ModalService } from "src/app/shared/services/modal.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-sale-details",
  templateUrl: "./sale.details.component.html",
  animations: [toggleAnimation],
})
export class SaleDetailsComponent implements OnInit {
  @Input() hideStatus = false;
  public sale!: Sale;
  private _id!: number;
  public payments!: Payment[];
  public totalDepth$!: Observable<number>;
  public editPayment!: Payment | undefined;

  messageRS = `Molim Vas za potrvdu. Racun je u dodatku emaila. `;
  messageEN = `Please confirm. Payment is in the email attachment. `;

  showDocumentPanel?: boolean = false;
  public paymentDocument?: PaymentDocument;

  form!: FormGroup;
  emailString?: string = "";
  invalidEmailMessage: string | null = null;
  additionalEmail?: string = "";
  public travelId: number | undefined;
  private routeSubscribtion!: Subscription;

  @ViewChild("modal") modal!: ModalComponent;
  @ViewChild("appPaymentForm") appPaymentForm!: PaymentFormComponent;

  @Output() notifyWhenEditAndSend: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() set id(value: number) {
    this._id = value;
    this.fetchSaleData();
  }
  get id(): number {
    return this._id;
  }
  private saleSubject = new BehaviorSubject<Sale | undefined>(undefined);
  sale$: Observable<Sale | undefined> = this.saleSubject.asObservable();

  constructor(public saleService: SalesService, public translate: TranslateService, private paymentService: PaymentService, private fb: FormBuilder, private documentService: DocumentService,protected modalService: ModalService,private route:ActivatedRoute) {
    this.totalDepth$ = combineLatest([this.sale$]).pipe(
      map(([sale]) => {
        if (!sale) return 0;
        const avans = sale.avans || 0;
        const totalPayments = sale.payments.reduce((acc: number, payment: Payment) => acc + (payment.payment || 0), 0);
        return sale.bruto - avans - totalPayments;
      })
    );
    this.form = this.fb.group({
      emails: this.fb.array([]),
    });
  }

  ngOnInit(): void {
    this.routeSubscribtion = this.route.params.subscribe((params) => {
      this.travelId = params["id"];
    });
  }

  fetchSaleData() {
    const fetchSubscription = this.saleService.getSaleDetails(this.id).subscribe((response) => {
      fetchSubscription.unsubscribe();
      this.sale = response;
      this.payments = this.sale.payments.map((p) => {
        return { ...p, guid: crypto.randomUUID() };
      });
      this.saleSubject.next(this.sale);
    });
  }

  toEditPayment(item: Payment) {
    this.editPayment = undefined;
    setTimeout(() => {
      this.editPayment = item;
    });
  }

  submitPayment() {
    const newPayment = this.appPaymentForm.getPayment();
    const updateSubscription = this.paymentService.updatePayment(newPayment).subscribe(() => {
      updateSubscription.unsubscribe();
      this.editPayment = undefined;
      this.fetchSaleData();
    });
  }

  discard() {
    this.editPayment = undefined;
  }

  submitNewPayment() {
    const newPayment = this.appPaymentForm.getPayment();
    const createSubscription = this.paymentService.savePayment({ ...newPayment, planId: this.id }).subscribe(() => {
      createSubscription.unsubscribe();
      this.editPayment = undefined;
      this.fetchSaleData();
    });
  }

  setNewPayment() {
    this.editPayment = {} as Payment;
  }

  removePayment(item: any) {
    const deleteSubscription = this.paymentService.deletePayment(item.id).subscribe(() => {
      deleteSubscription.unsubscribe();
      this.editPayment = undefined;
      this.fetchSaleData();
    });
  }
  showDocument(item: any) {
    if (this.paymentDocument?.id === item.id) {
      this.showDocumentPanel = !this.showDocumentPanel;
    } else {
      const getPaymentSubscription = this.paymentService.getPaymentDocument(item.id).subscribe((response) => {
        getPaymentSubscription.unsubscribe();
        this.paymentDocument = response;
        this.showDocumentPanel = true;
      });
    }
  }
  printPayment() {
    window.print();
    // TODO: To be implemented
    // this.paymentService.getPaymentPrintingData(this.paymentDocument?.id).subscribe((resp) => {
    //   this.documentService.printFiscalReciept(resp.value).subscribe(
    //     () => {
    //       showMessage(PopupType.Success, this.translate.instant("printed_successfully"));
    //     },
    //     (err) => {
    //       showMessage(PopupType.Success, this.translate.instant("print_not_successfull"));
    //     }
    //   );
    // });
  }

  sendPayment() {
    this.modal.open();
    this.notifyWhenEditAndSend.emit();
  }
  onEditPaymentChange(editPayment: Payment | undefined) {
    this.editPayment = editPayment;
  }

  finishSending() {
    this.modal.close();
  }
  async sendInvoice() {
    const confirm = await this.modalService.openModalForSendingInovice({
      title: this.translate.instant("send.avans"), // TODO:: translate (not the correct title)
      message: this.translate.instant("confirm"), // TODO:: translate (not the correct message)
      confirmButtonText: this.translate.instant("yes"),
      cancelButtonText: this.translate.instant("no"),
      plan: this.sale,
      navigateToRoute: `dashboard/travels/${this.travelId}`,
    });
    if (confirm) {
      showMessage(PopupType.Success, this.translate.instant("popup.avans_sent_successfully"));
    }
  }
}
