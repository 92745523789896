<div class="space-y-8">
  <div class="relative flex h-full gap-1 sm:h-[calc(100vh_-_150px)]">
    <div
      class="panel absolute z-10 hidden h-full w-[280px] max-w-full flex-none space-y-4 p-4 xl:relative xl:block xl:h-auto ltr:rounded-r-none ltr:xl:rounded-r-md rtl:rounded-l-none rtl:xl:rounded-l-md"
      [ngClass]="{ '!block': isShowTaskMenu }"
    >
      <div class="flex h-full flex-col pb-16">
        <div class="pb-5">
          <div class="flex items-center text-center">
            <div class="shrink-0">
              <icon-euro />
            </div>
            <h3 class="text-lg font-semibold ltr:ml-3 rtl:mr-3">{{ "cash_register.name" | translate }}</h3>
          </div>
        </div>
        <div class="mb-5 h-px w-full border-b border-[#e0e6ed] dark:border-[#1b2e4b]"></div>
        <ng-scrollbar class="relative h-full grow ltr:pr-3.5 rtl:pl-3.5" appearance="compact">
          <div class="space-y-1">
            <button
              type="button"
              class="flex h-10 w-full items-center justify-between rounded-md p-2 font-medium hover:bg-white-dark/10 hover:text-dark dark:hover:bg-[#181F32] dark:hover:text-dark"
              (click)="tabChanged(cashRegister.id)"
              *ngFor="let cashRegister of cashRegisters"
            >
              <div class="flex items-center">
                <icon-calendar-colored></icon-calendar-colored>
                <div class="ltr:ml-3 rtl:mr-3">{{ cashRegister.processedFrom }} - {{ cashRegister.processedAt }}</div>
              </div>
              <div class="whitespace-nowrap rounded-md bg-primary-light px-2 py-0.5 font-semibold dark:bg-[#060818]">
                {{ cashRegister.plans.length }}
              </div>
            </button>
          </div>
        </ng-scrollbar>
        <div class="absolute bottom-0 w-full p-4 ltr:left-0 rtl:right-0">
          <button class="btn btn-primary w-full" type="button" (click)="openModal()">
            <icon-plus class="shrink-0 ltr:mr-2 rtl:ml-2" />
            {{ "cash_register.add_cash_register" | translate }}
          </button>
        </div>
      </div>
    </div>
    <div class="overlay absolute z-[5] hidden h-full w-full rounded-md bg-black/60" [ngClass]="{ '!block xl:!hidden': isShowTaskMenu }" (click)="isShowTaskMenu = !isShowTaskMenu"></div>
    <div class="panel h-full flex-1 overflow-auto p-0">
      <div class="flex h-full flex-col">
        <div class="flex w-full flex-col gap-4 p-4 sm:flex-row sm:items-center">
          <div class="flex items-center ltr:mr-3 rtl:ml-3">
            <button type="button" class="btn btn-danger mr-2" (click)="isShowTaskMenu = !isShowTaskMenu"><icon-top-right /> {{ "open_list_of_sale_registers" | translate }}</button>
            <div class="group relative flex-1">
              <input type="text" class="peer form-input ltr:!pr-10 rtl:!pl-10" placeholder="{{ 'search_task' | translate }}..." name="searchTask" [(ngModel)]="searchTask" (ngModelChange)="searchTasks()" />
              <div class="absolute top-1/2 -translate-y-1/2 peer-focus:text-primary ltr:right-[11px] rtl:left-[11px]">
                <icon-search />
              </div>
            </div>
          </div>
          <div class="flex flex-1 items-center justify-center sm:flex-auto sm:justify-end">
            <div class="mr-2 flex items-center" *ngIf="display">
              <icon-calendar-colored></icon-calendar-colored>
              <div class="ltr:ml-3 rtl:mr-3">{{ fromDate | date : "dd.MM.yyyy" }} - {{ toDate | date : "dd.MM.yyyy" }}</div>
            </div>
            <button (click)="openDocument()" type="button" class="btn btn-outline-primary mr-2" [disabled]="!cashRegisterId">
              {{ "cash_register.open_document" | translate }}
            </button>
            <button type="button" class="btn btn-outline-primary mr-2" ngxTippy="{{ 'cash_register.add_sale_in_cash_register' | translate }}" (click)="openSaleModal()" [disabled]="!cashRegisterId">
              {{ "cash_register.add_sale" | translate }}
            </button>
          </div>
        </div>
        <div class="h-px w-full border-b border-[#e0e6ed] dark:border-[#1b2e4b]"></div>
        <ng-container>
          <div class="datatable" *ngIf="cashRegisterId">
            <ng-datatable
              [rows]="plans"
              [columns]="cols"
              [sortable]="true"
              [loading]="loading"
              [pageSize]="pageSize"
              [totalRows]="totalRows"
              skin="whitespace-nowrap table-hover"
              [paginationInfo]="'paginationInfoLabel' | translate"
              [noDataContent]="'empty_tables' | translate"
            >
              <ng-template slot="customer" let-value="data">
                <span>{{ value?.customer.name }} {{ value?.customer.surname }}</span>
              </ng-template>
              <ng-template slot="purchased" let-value="data">
                <span>{{ value.createdAt | date : "dd.MM.yyyy" }}</span>
              </ng-template>
              <ng-template slot="status" let-value="data">
                <span class="badge bg-success" [ngClass]="{ 'bg-danger': !value.status, 'bg-success': value.status }">
                  <span *ngIf="value.status; else unpaidTemplate">{{'paid' | translate }}</span>
                  <ng-template #unpaidTemplate>
                    <span>{{ 'not_paid'| translate}}</span>
                  </ng-template>
                </span>
              </ng-template>
              <ng-template slot="sold" let-value="data">
                <span><!-- {{ value.sold }} --></span>
              </ng-template>
              <ng-template slot="advance_payment" let-value="data">
                <span>{{ value.avans | currency : "EUR" }}</span>
              </ng-template>
              <ng-template slot="total_price" let-value="data">
                <span>{{ value.bruto | currency : "EUR" }}</span>
              </ng-template>
              <ng-template slot="payment_deadline" let-value="data">
                <span>{{ value.dueDate | date : "dd.MM.yyyy" }}</span>
              </ng-template>
            </ng-datatable>
          </div>
        </ng-container>
        <ng-container *ngIf="!plans.length && cashRegisterId">
          <div class="flex h-full min-h-[400px] items-center justify-center text-lg font-semibold sm:min-h-[300px]">{{ "no_data_daily_sale" | translate }}</div>
        </ng-container>
        <ng-container *ngIf="!cashRegisterId">
          <div class="flex h-full min-h-[400px] items-center justify-center text-lg font-semibold sm:min-h-[300px]">{{ "no_register_selected_daily_sale" | translate }}</div>
        </ng-container>
      </div>
    </div>
  </div>
  <modal #addCashRegisterModal>
    <ng-template #modalHeader>
      <div class="!font-medium">{{ "cash_register.add_cash_register" | translate }}</div>
    </ng-template>
    <ng-template #modalBody>
      <form [formGroup]="form" class="text-sm" (ngSubmit)="saveCashRegister()">
        <div class="mt-3 grid grid-cols-1 gap-5 md:grid-cols-1">
          <div [ngClass]="form.controls['processedFrom'].touched ? (form.controls['processedFrom'].errors ? 'has-error' : 'has-success') : ''">
            <label for="processedFrom">{{ "cash_register.processed_from" | translate }}</label>
            <input id="processedFrom" type="date" placeholder="{{ 'worker.enter_processed_from' | translate }}" class="form-input" formControlName="processedFrom" />
            <ng-container *ngIf="form.controls['processedFrom'].touched && form.controls['processedFrom'].errors">
              <p class="mt-1 text-danger">
                <small>{{ "cash_register.please_provide_start_date" | translate }}</small>
              </p>
            </ng-container>
          </div>
        </div>
        <div [ngClass]="form.controls['processedAt'].touched ? (form.controls['processedAt'].errors ? 'has-error' : 'has-success') : ''">
          <label for="processedAt">{{ "cash_register.processed_at" | translate }}</label>
          <input id="processedAt" type="date" placeholder="{{ 'cash_register.enter_processed_at' | translate }}" class="form-input" formControlName="processedAt" />
          <ng-container *ngIf="form.controls['processedAt'].touched && form.controls['processedAt'].errors">
            <p class="mt-1 text-danger">
              <small>{{ "cash_register.please_provide_end_date" | translate }}</small>
            </p>
          </ng-container>
        </div>
        <div class="mt-8 flex items-center justify-end">
          <button type="submit" (click)="closeDocument()" class="btn btn-primary">{{ "cash_register.add" | translate }}</button>
        </div>
      </form>
    </ng-template>
  </modal>

  <!-- Modal -->
  <modal #addSaleInCashRegister class="modal-top extra-large-modal">
    <ng-template #modalHeader>
      <div class="!font-medium">
        <label class="font-large mt-2 flex items-center">
          {{ "daily_sale_new_selected" | translate : { plansCount: this.datatable?.getSelectedRows()?.length } }}
        </label>
      </div>
      <div class="flex items-center">
        <div class="ltr:ml-3 rtl:mr-3">
          <input type="date" [ngModel]="fromDate" (change)="handleFromDateChange($event)" class="form-control" />
          <span> - </span>
          <input type="date" [ngModel]="toDate" (change)="handleToDateChange($event)" class="form-control" />
        </div>
      </div>
    </ng-template>
    <ng-template #modalBody>
      <div class="datatable">
        <ng-datatable
          #datatable
          [rows]="plansWithoutCashRegister"
          [columns]="cols"
          [sortable]="true"
          [isServerMode]="true"
          [loading]="loading"
          [hasCheckbox]="true"
          [pageSize]="pagerWithoutCashRegister.pageSize"
          [totalRows]="pagerWithoutCashRegister.totalCount"
          (changeServer)="changeServer($event)"
          skin="whitespace-nowrap table-hover"
          [paginationInfo]="'paginationInfoLabel' | translate"
          [noDataContent]="'empty_tables' | translate"
        >
          <ng-template slot="customer" let-value="data">
            <span>{{ value.customer.name }} {{ value.customer.surname }}</span>
          </ng-template>
          <ng-template slot="purchased" let-value="data">
            <span>{{ value.createdAt | date : "dd.MM.yyyy" }}</span>
          </ng-template>
          <ng-template slot="status" let-value="data">
            <span class="badge bg-success" [ngClass]="{ 'bg-danger': !value.status, 'bg-success': value.status }">
              <span *ngIf="value.status; else unpaidTemplate">{{'paid' | translate }}</span>
              <ng-template #unpaidTemplate>
                <span>{{ 'not_paid'| translate}}</span>
              </ng-template>
            </span>
          </ng-template>
          <ng-template slot="sold" let-value="data">
            <span><!-- {{ value.sold }} --></span>
          </ng-template>
          <ng-template slot="advance_payment" let-value="data">
            <span>{{ value.avans }}</span>
          </ng-template>
          <ng-template slot="total_price" let-value="data">
            <span>{{ value.bruto }}</span>
          </ng-template>
          <ng-template slot="payment_deadline" let-value="data">
            <span>{{ value.dueDate | date : "dd.MM.yyyy" }}</span>
          </ng-template>
        </ng-datatable>
      </div>
    </ng-template>
    <ng-template #modalFooter>
      <div class="flex items-center justify-end">
        <button type="button" class="btn btn-primary" (click)="addPlansInCashRegister()">
          {{ "cash_register.add" | translate }}
        </button>
      </div>
    </ng-template>
  </modal>
</div>
