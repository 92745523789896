import * as moment from "moment";
import { Subscription } from "rxjs";
import { Store } from "@ngrx/store";
import { Travel } from "../models/travel";
import { TranslateService } from "@ngx-translate/core";
import { AppState } from "src/app/store/index.selector";
import { UtilService } from "src/app/shared/services/util.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { dateFormatValidator } from "src/app/shared/validators/date.validator";
import { PicklistType, EntityPicklistType } from "src/app/shared/enums/picklist-types";
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { toggleAnimation } from "src/app/shared/animations";

@Component({
  selector: "app-travel-create",
  templateUrl: "./travel.create.component.html",
  animations: [toggleAnimation],
})
export class TravelCreateComponent implements OnInit, AfterViewInit {
  public id: number | undefined;
  travel: Travel | undefined;

  PicklistType = PicklistType;
  EntityPicklistType = EntityPicklistType;

  public form!: FormGroup;
  public isSubmitForm = false;

  public travelTypeSubscription: Subscription | undefined;
  @Input() showAvans = false;
  @Output() notify: EventEmitter<{ start: string; end: string, days: number, nights: number }> = new EventEmitter();
  @ViewChild("startInput") startInput!: ElementRef;

  constructor(public store: Store<AppState>, public translate: TranslateService, public fb: FormBuilder, public utilService: UtilService) {}
  initForm(travel: Travel) {
    const { id = this.id, start, end, typeId = 5, type, days, nights, organizer, departure, plan} = travel || ({} as Travel);
    const { avans } = plan || ({} as Travel);
    this.id = id;
    this.travel = travel;
    const country = departure?.country || null;
    this.form = this.fb.group({
      start: [start, { validators: [Validators.required, dateFormatValidator()], updateOn: "blur" }],
      end: [end, { validators: [Validators.required, dateFormatValidator()], updateOn: "blur" }],
      type: [type, { validators: [Validators.required], updateOn: "blur" }],
      days: [days, { validators: [Validators.required], updateOn: "blur" }],
      nights: [nights, { validators: [Validators.required], updateOn: "blur" }],
      organizer: [organizer, Validators.required],
      departure: [departure],
      country: [country],
      avans: ["", this.showAvans ? Validators.required : []],
    });

    this.travelTypeSubscription = this.form.get("type")?.valueChanges.subscribe((value) => {
      const departControl = this.form.get("depart");
      if (departControl) {
        if (value.id !== 7) {
          departControl.clearValidators();
        } else {
          departControl.setValidators([Validators.required]);
        }
        departControl.updateValueAndValidity();
      }
    });
  }

  setDaysNights() {
    if (!this.form.controls["start"].errors && !!this.form.controls["start"].value) {
      const start = moment(this.form.controls["start"].value, "DD.MM.YYYY", true);
      const newEndDate = start.add(this.form.controls["days"].value, "days");

      this.form.controls["end"].setValue(newEndDate.format("DD.MM.YYYY"));
    }
  }

  dateMask = [/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/];

  submitForm() {
    this.isSubmitForm = true;
  }

  checkIfValid(): boolean {
    this.form.updateValueAndValidity();
    if (this.form.invalid) {
      // The form is invalid, you can loop through the controls to find the invalid ones
      for (const controlName in this.form.controls) {
        const control = this.form.get(controlName);
        if (control?.invalid) {
          console.log(`Control ${controlName} is invalid`);
          console.log(`Control ${control.value} is invalid`);
          // You can also access specific error messages using control.errors
        }
      }
    }
    return this.form.valid;
  }

  ngOnInit(): void {
    this.initForm({} as Travel);
  }

  getTravel(): Travel {
    return { ...this.form.getRawValue(), id: this.id };
  }

  ngOnDestroy() {
    if (this.travelTypeSubscription) {
      this.travelTypeSubscription.unsubscribe();
    }
  }

  onEndBlur() {
    this.notify.emit({ start: this.form.controls["start"].value, end: this.form.controls["end"].value, days: this.form.controls["days"].value, nights: this.form.controls["nights"].value });
  }

  ngAfterViewInit() {
    this.startInput.nativeElement.focus();
  }
}
