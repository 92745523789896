<div class="modal-overlay">
  <div class="modal-content">
    <div class="mx-auto w-full">
      <app-file-send
        [title]="'invoicesendemail' | translate"
        fileType="payment"
        [passangers]="[plan.customer]"
        [sendDocumentFn]="paymentService.sendPayment(this.plan.id)"
        messageRs="Racun se nalazi u dodatku maila"
        messageEn="Invoice is in the attachement of the mail"
        (notifyWhenSend)="finishSending()"
      >
        <div class="mb-6 flex flex-wrap items-center justify-center gap-4 lg:justify-end print:hidden">
          <button type="button" class="btn btn-primary gap-2" (click)="printInvoice()">
            <icon-printer />
            {{ "invoice_preview.print" | translate }}
          </button>
          <button type="button" class="btn btn-danger gap-2" (click)="dismiss()" [disabled]="isLoading">
            <icon-x />
            {{ "invoice_preview.close" | translate }}
          </button>
        </div>
        <div class="panel relative mx-auto w-full max-w-5xl rounded-lg border border-gray-200 bg-white p-6 shadow-lg dark:border-gray-700 dark:bg-gray-900" id="payment">
          <div class="flex items-center justify-between">
            <div class="flex flex-wrap justify-start" *ngIf="plan.createdBy.officeBranch.logoImage">
              <img [src]="plan.createdBy.officeBranch.logoImage.url" alt="" class="w-15 h-10" />
            </div>
            <div class="d-flex justify-content-between align-items-center">
              <div class="flex flex-wrap justify-end">
                <span class="mr-2">{{ plan.createdBy.officeBranch.fullAddress }}</span>
                <icon-home />
              </div>
              <div class="flex flex-wrap justify-end">
                <span class="mr-2">{{ plan.createdBy.officeBranch.phoneNumber }} </span>
                <icon-phone />
              </div>
              <div class="flex flex-wrap justify-end">
                <span class="mr-2">{{ plan.createdBy.officeBranch.email }} </span>
                <icon-mail />
              </div>
              <div class="flex flex-wrap justify-end">
                <span class="mr-2">https://{{ plan.createdBy.officeBranch.siteURL }} </span>
                <icon-chrome />
              </div>
              <div class="flex flex-wrap justify-end">
                <span class="mr-2">{{ "giro-account" | translate }}: {{ plan.createdBy.officeBranch.bankAccount }},PIB:{{ plan.createdBy.officeBranch.pib }} </span>
                <icon-zip-file />
              </div>
            </div>
          </div>
          <div class="mt-5 flex flex-col">
            <div class="flex items-center justify-between">
              <p>{{ "paymentdetails.createdAt" | translate }}:</p>
              <div>{{ plan.createdAt | date : "dd.MM.yyyy" }}</div>
            </div>
            <div class="mt-4 flex items-center justify-between">
              <p>{{ "paymentdetails.customerFullName" | translate }}:</p>
              <div>{{ plan.customer.name + " " + plan.customer.surname }}</div>
            </div>
            <div class="mt-4 flex items-center justify-between">
              <p>{{ "paymentdetails.email" | translate }}:</p>
              <div>{{ plan.customer.email }}</div>
            </div>
            <div class="mt-4 flex items-center justify-between" *ngIf="isOnlyAvans">
              <p>{{ "paymentdetails.total" | translate }}:</p>
              <div>
                {{ (plan.bruto || 0) - (plan.avans || 0) | currency : "EUR" }}
              </div>
            </div>
            <div class="mt-4 flex items-center justify-between">
              <p>{{ "paymentdetails.avans" | translate }}:</p>
              <div>{{ plan.avans ?? 0 | currency : "EUR" }}</div>
            </div>
            <div class="mt-4 flex items-center justify-between">
              <p>{{ "paymentdetails.paymentType" | translate }}:</p>
              <div>{{ plan.paymentType.title }}</div>
            </div>
            <div class="mt-4 flex items-center justify-between">
              <p>{{ "paymentdetails.paymentDate" | translate }}:</p>
              <div>{{ plan.paymentType.createdAt | date : "dd.MM.yyyy" }}</div>
            </div>
          </div>
          <hr class="my-2 border-[#e0e6ed] dark:border-[#1b2e4b]" />
          <div class="mt-4">
            <div class="mx-auto rounded-lg">
              <div class="flex justify-between">
                <p>
                  <strong>{{ "paymentdetails.dateOfPayment" | translate }}:</strong> {{ getCurrentDate() | date : "dd.MM.yyyy" }}
                </p>
                <p>
                  <strong>{{ "paymentdetails.director" | translate }}:</strong> Esad Gicic
                </p>
              </div>
            </div>
          </div>
        </div>
      </app-file-send>
    </div>
  </div>
</div>
