<div *ngIf="isLoading" class="screen_loader animate__animated absolute inset-0 z-[35] grid place-content-center bg-[#fafafa] opacity-65 dark:bg-[#060818]">
  <img class="ml-[5px] h-auto w-20 flex-none" src="/assets/images/loader.svg" alt="" />
</div>
<button type="button" class="btn btn-primary gap-2 p-0 px-1" (click)="toggleFileUploadPopup()">
  <icon-down></icon-down>
  <img src="assets/images/eturista-logo.png" class="w-18 h-9" />
</button>

<div class="mr-50 mr-3 flex flex-col items-center">
  <div *ngIf="isPopupVisible" class="absolute z-10 mt-3 w-auto transform flex-col rounded-lg border border-gray-200 bg-white p-3 pt-3 opacity-95 shadow-lg transition-opacity">
    <div class="space-y-3 px-2 py-2 text-blue-600">
      <button
        type="button"
        (click)="downloadETuristConfirmation()"
        class="btn btn-primary d-flex align-items-center w-full transform justify-start gap-2 rounded-lg p-2 text-white shadow-md transition-transform hover:scale-105"
      >
        <icon-eye></icon-eye>
        {{ "account_data.download_confirmation" | translate }}
      </button>
      <button
        type="button"
        (click)="updateETuristConfirmation()"
        class="btn btn-primary d-flex align-items-center w-full transform justify-start gap-2 rounded-lg p-2 text-white shadow-md transition-transform hover:scale-105"
      >
        <icon-accounting-data></icon-accounting-data>
        {{ "account_data.update_confirmation" | translate }}
      </button>
      <button
        type="button"
        (click)="deleteETuristConfirmation()"
        class="btn btn-danger d-flex align-items-center w-full transform justify-start gap-2 rounded-lg p-2 text-white shadow-md transition-transform hover:scale-105"
      >
        <icon-accounting-data></icon-accounting-data>
        {{ "account_data.delete_confirmation" | translate }}
      </button>
    </div>
  </div>
</div>

<modal #eturistPdfModal class="modal-top custom-modal" [closeOnOutsideClick]="false">
  <ng-template #modalHeader>
    <div class="!py-5">{{ "account_data.record_sale" | translate }}</div>
		<button (click)="closeModal()" class="absolute right-2 top-2 text-gray-500 hover:text-gray-700">
			<icon-x class="h-5 w-5"></icon-x>
		</button>
  </ng-template>
  <ng-template #modalBody>
    <app-travel-e-turist-pdf-viewer *ngIf="fileUrl" [fileUrl]="fileUrl" (eventClose)="addedRecordToEturist()" [email]="emails" [modalPdf]="eturistPdfModal"></app-travel-e-turist-pdf-viewer>
  </ng-template>
</modal>

<modal #eturistUpdateModal class="modal-top">
  <ng-template #modalHeader>
    <div class="!py-5">{{ "account_data.record_sale" | translate }}</div>
  </ng-template>
  <ng-template #modalBody>
    <app-travel-e-turist-form-update *ngIf="travelId" [plan]="plan" [id]="travelId" (eventClose)="addedRecordToEturist()" [IsGroupETourist]="IsGroupETourist"></app-travel-e-turist-form-update>
  </ng-template>
</modal>
