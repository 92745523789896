import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Announcement } from "../models/announcement";
import { BaseHttpService } from "src/app/service/base.http.service";

@Injectable({
  providedIn: "root",
})
export class AnnouncementService extends BaseHttpService {
  constructor(private http: HttpClient) {
    super();
  }
  getAnnouncementsByTravel(id: number): Observable<Announcement> {
    return this.http.get<Announcement>(`${this.apiUrl}/api/Announcements/travel/${id}`);
  }

  getTravelDraft(id: number): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/api/travels/draft/${id}`);
  }
  saveAnnouncement(body: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/api/Announcements`, body);
  }

  sendAnnouncement(id: number): (body: FormData) => Observable<any> {
    return (body: FormData) => {
      body.append("Id", id.toString());
      return this.http.post(`${this.apiUrl}/api/Announcements/send`, body);
    };
  }
  updateAnnouncement(body: any, id: number, travelDestinationId: number): Observable<any> {
    return this.http.put(`${this.apiUrl}/api/Announcements/${id}/${travelDestinationId}`, body);
  }

  changeAnnouncementStatus(id: number, status: number): Observable<any> {
    return this.http.put(`${this.apiUrl}/api/Draft/travel/${id}/announcement/${status}`, {});
  }

  stringToHtmlFile(htmlContent: string, fileName: string): File {
    const blob = new Blob([htmlContent], { type: "text/html" });
    return new File([blob], fileName, { type: "text/html" });
  }
}
