import { Subscription } from "rxjs";
import { Store } from "@ngrx/store";
import { ActivatedRoute } from "@angular/router";
import { Office } from "../models/office-branch";
import { ModalComponent } from "angular-custom-modal";
import { TranslateService } from "@ngx-translate/core";
import { AppState } from "src/app/store/index.selector";
import { UserWorker } from "../../workers/models/worker";
import { PopupType } from "src/app/shared/enums/popup-types";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { ModalService } from "src/app/shared/services/modal.service";
import { OfficeBranchService } from "../services/office-branch.service";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ETouristService } from "src/app/shared/services/etourist.service";
import { OfficeBranchFormComponent } from "../office-branch.form.component/office-branch.form.component";
import { toggleAnimation } from "src/app/shared/animations";

@Component({
  selector: "app-office-branch-list",
  templateUrl: "./office-branch.list.component.html",
  animations: [toggleAnimation],
})
export class OfficeBranchListComponent implements OnInit, OnDestroy {
  @ViewChild("addOfficeLocationModal") addOfficeLocationModal!: ModalComponent;
  @ViewChild("createOfficeModal") createOfficeModal!: ModalComponent;
  @ViewChild("officeBranchForm") officeBranchForm!: OfficeBranchFormComponent;

  public offices: Office[] = [];
  public isEdit = false;

  public branchId!: number | undefined;
  public office!: any[];
  private officeBranchSub!: Subscription;
  protected message!: string;
  protected checked!: boolean;

  constructor(
    private route: ActivatedRoute,
    public store: Store<AppState>,
    public translate: TranslateService,
    private officeBranchService: OfficeBranchService,
    private modalService: ModalService,
    public eTouristService: ETouristService
  ) {}

  ngOnInit(): void {
    this.getOfficeBranches();
    this.getETuristAvailability();
  }

  ngOnDestroy(): void {
    this.officeBranchSub.unsubscribe();
  }

  getETuristAvailability() {
    this.eTouristService.getAvailability().subscribe((check) => {
      this.checked = check;
      this.message = check ? this.translate.instant("e-turist-success") : this.translate.instant("e-turist-error", { link: "https://turistagent.com/uputstva" });
    });
  }

  getUsersNameList(users: UserWorker[]) {
    if (users.length === 0) return this.translate.instant("empty_office_branch");
    return users
      .map((u) => {
        return `${u.name}  ${u.surname}`;
      })
      .join(", ");
  }

  getOfficeBranches() {
    this.officeBranchSub = this.officeBranchService.getOffices().subscribe((res) => {
      this.offices = res;
    });
  }

  openModalAddingOfficeLocation(id: number) {
    if (this.checked) {
      this.branchId = id;
      this.addOfficeLocationModal.open();
    }
  }

  closeLocationsModal() {
    this.branchId = undefined;
    this.addOfficeLocationModal.close();
    this.getOfficeBranches();
  }

  closeModal() {
    this.createOfficeModal.close();
    this.getOfficeBranches();
  }

  openModalForCreating() {
    this.branchId = undefined;
    this.createOfficeModal.open();
  }

  openModalForEditing(id: number) {
    this.branchId = id;
    this.isEdit = true;
    this.createOfficeModal.open();
  }

  async deleteOfficeBranch(id: number) {
    const confirmed = await this.modalService.confirm();
    if (confirmed) {
      this.offices = this.offices.filter((office: Office) => office.id !== id);
      showMessage(PopupType.Success, this.translate.instant("popup.office_branch_deleted_successfully"));
      // TODO: Add Delete API
    }
  }
}
