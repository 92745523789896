<div class="font-large flex items-center justify-center pb-10 text-base text-[#1f2937] dark:text-dark">
  <h1 class="text-start">{{ title }}</h1>
</div>
<div class="space-y-5">
  <div class="mb-4">
    <ng-content></ng-content>
    <div class="mb-4">
      <label for="additionalEmails">{{ "contractsendemail.additionalemails" | translate }}:</label>
      <tag-input
        [(ngModel)]="emails"
        [modelAsStrings]="true"
        [placeholder]="'contractsendemail.email_to_send' | translate"
        [secondaryPlaceholder]="'contractsendemail.email_to_send' | translate"
        [maxItems]="4"
        [blinkIfDupe]="true"
        [validators]="validators"
        [trimTags]="true"
        [errorMessages]="errorMessages"
      ></tag-input>
      <p>{{ "contractsendemail.email_hint" | translate }}</p>
    </div>
  </div>
  <form [formGroup]="form" (submit)="sendDocument()" class="gap-5">
    <div class="mb-4">
      <label for="messageRS" class="mb-1 block">{{ "contractsendemail.messageRS" | translate }}</label>
      <textarea id="messageRS" class="form-textarea" formControlName="messageRS" rows="3"></textarea>
    </div>
    <div class="mb-4">
      <label for="messageEN" class="mb-1 block">{{ "contractsendemail.messageEN" | translate }}</label>
      <textarea id="messageEN" class="form-textarea" formControlName="messageEN" rows="3"></textarea>
    </div>
    <button type="submit" class="btn btn-primary ltr:ml-4 rtl:mr-4" [disabled]="isLoading">
      <ng-container *ngIf="isLoading; else alternateTranslation">
        <span class="inline-block h-3 w-3 animate-ping rounded-full bg-white ltr:mr-4 rtl:ml-4"></span>
        {{ "loading" | translate }}
      </ng-container>
      <ng-template #alternateTranslation>
        {{ "send" | translate }}
      </ng-template>
    </button>
  </form>
</div>
